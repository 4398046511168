import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Typography, useTheme } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';
import { devConsoleLog, getStringProduct } from '../../../utils/utilityFunctions';

const FormControl = styled(FormControlLabel)(({ theme }) => ({}));

const Text = styled('span')(({ theme, checkboxcolortext, fontsize, smfontsize, lineheight, fontweight, textalign }) => ({
  fontFamily: 'Poppins!important',
  fontStyle: 'normal',
  fontWeight: fontweight || '400',
  fontSize: fontsize || '16px',
  lineHeight: lineheight || '34px',
  textAlign: textalign,
  color: checkboxcolortext || theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    fontSize: smfontsize ? `${smfontsize}px !important` : '9px !important'
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, fontsize) || '48px',
    lineHeight: getStringProduct(3,  lineheight) || '102px'
  },

}));

const BpIcon = styled('span')(({ theme, checkboxcolor = '' }) => ({
  borderRadius: 5,
  width: 17,
  height: 17,
  padding: '2px',
  border: `2px solid ${checkboxcolor || theme.palette.border.border}`,
  backgroundColor: 'transparent',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  },
  [theme.breakpoints.up('xl4')]: {
    borderRadius: 15,
    width: 51,
    height: 51,
    padding: '6px',
    border: `6px solid ${checkboxcolor || theme.palette.border.border}`,
    }
}));

const BpCheckedIcon = styled(BpIcon)(({ theme, checkboxcolor = '' }) => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    borderRadius: 3,
    backgroundColor: checkboxcolor || theme.palette.border.border,
    content: '""'
  },
  [theme.breakpoints.up('xl4')]: {
    '&:before': {
    borderRadius: 9,
  },
  }
}));

// function BpCheckbox(props) {
//   const { label, checkboxcolor, handleChange, checked, margintop, ...rest } = props;
//   return (
//     <FormControl
//       onChange={handleChange}
//       checked={checked}
//       sx={{ alignItems: 'flex-start' }}
//       control={
//         <Checkbox
//           sx={{
//             marginTop: margintop || '-1px',
//             '&:hover': { bgcolor: 'transparent' }
//           }}
//           {...props}
//           disableRipple
//           color="default"
//           checkedIcon={<BpCheckedIcon checkboxcolor={checkboxcolor} />}
//           icon={<BpIcon checkboxcolor={checkboxcolor} />}
//           inputProps={{ 'aria-label': 'Checkbox demo' }}
//         />
//       }
//       label={
//         <Text {...props} variant="caption">
//           {label}
//         </Text>
//       }
//     />
//   );
// }

export default function FreedomCheckbox1(props) {
  const theme = useTheme()
  try {
    const { label, checkboxcolor, handlechange, checked, margintop, fontsize, checkboxpadding, alignitems='flex-start', ...rest } = props;
    devConsoleLog('rest operator', { ...rest });
    return (
      <FormControlLabel
        onChange={handlechange}
        checked={checked}
        sx={{ alignItems: alignitems }}
        control={
          <Checkbox
            sx={{
              marginTop: margintop || '-1px',
              '&:hover': { bgcolor: 'transparent' },
              padding: checkboxpadding || '6px',
              [theme.breakpoints.up('xl4')]: {
                marginTop: getStringProduct(3, margintop) || '-3px',
                padding: getStringProduct(3, checkboxpadding) || '18px',
              }
            }}
            {...rest}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon checkboxcolor={checkboxcolor} />}
            icon={<BpIcon checkboxcolor={checkboxcolor} />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
          />
        }
        label={
          <Text fontsize={fontsize} {...rest} variant="caption">
            {label}
          </Text>
        }
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
}
