// export const investorsdashboard = "/investorsdashboard"
// export const invitenewinvestor ="/invitenewinvestor"

export const DashboardUrls = {
  mainDashboard: '/maindashboard',
  getStartedWizard: '/getstarted',
  getStartedInvestment: '/getstarted/get-started-investment'
};

export const youtubevideos = {
  connectBrokerAccount: 'https://www.youtube.com/watch?v=49W9myEyKwo',
  createBrokerAccount_IC_market: 'https://www.youtube.com/watch?v=H6ULXRkYVng',
  createBrokerAccount_Pepperstone: 'https://www.youtube.com/watch?v=eARru9tqK80',
  chooseProduct: 'https://www.youtube.com/watch?v=CpFQL3Hf8Zc',
  changeProduct: 'https://www.youtube.com/watch?v=ZpG3G6V79-4',
  productSettings: 'https://www.youtube.com/watch?v=CpFQL3Hf8Zc',
  reconnectBrokerAccount: 'https://youtu.be/ZSUdgkcA85Y',
  /**affiliate video */
  mainDashboard: 'https://youtu.be/g83w6LHodJA',
  affiliateDashboard: 'https://youtu.be/JFAo6-hF5Do',
  inviteAffiliate: 'https://www.youtube.com/watch?v=ORPH0FOLUPc',
  /**investor video */
  inviteInvestor: 'https://youtu.be/OfAM5y-YwBA',
  /**friends video */
  inviteFriend: 'https://youtu.be/THAgUabGi_w',
  /**voucher video */
  createVoucher: 'https://www.youtube.com/watch?v=68lwDwHssXY',
  /**My Settings videos */
  changeInformation: 'https://www.youtube.com/watch?v=a7zqZuqDxKE'
}

export const InvestorUrls = {
  investorMainScreen: '/investorsmainscreen',
  investorDashboard: '/investors-dashboard',
  inviteNewInvestor: '/invite-investor',
  allInvestorDashboard: 'allinvestordashboard',
  pendingInvestors: '/allinvestordashboard/pending-investors',
  manageInvestors: '/allinvestordashboard/manage-investors',
  inviteInvestor: '/inviteinvestor',
  allInvestors: '/allinvestordashboard/manage-investors',
  allPendingInvestors: '/allinvestordashboard/pending-investors'
};

export const AffiliateUrls = {
  welcomeAffiliate: '/welcomeaffliate',
  affiliateDashboard: '/affliates-dashboard',
  inviteAffiliate: '/invite-affiliate',
  inviteInvestorAsAffiliate: '/inviteinvestorasaffiliate',
  allAffiliateDashboard: 'allaffiliatedashboard',
  allAffiliateDownline: '/allaffiliatedashboard/downline-affiliate',
  allAffiliateFirstline: '/allaffiliatedashboard/firstline-affiliates',
  allAffiliate: '/allaffiliatedashboard/direct-affiliates',
  allPendingAffiliates: '/allaffiliatedashboard/pending-affiliates',
  pendingAffiliate: 'pending-affiliates',
  manageAffiliate: 'direct-affiliates',
  downline: 'downline-affiliate',
  firstLineAffiliates: 'firstline-affiliates'
};

export const InvestmentUrls = {
  investmentDashboard: '/investmentsdashboard',
  investmentDashboardMainScreen: '/investmentsdashboardmainscreen',
  selectInvestmentPlan: '/selectinvestmentplan',
  investmentPlanList: '/investmentplanlist',
  investmentOverallSummary: '/investmentoverallsummary',
  newInvestment: '/newinvestment',
  newInvestmentPlan: '/newinvestmentplan',
  basicNewInvestmentPackage: '/newinvestment/basicnewinvestmentpackage',
  autoInvest: '/autoinvest',
  autoRenew: '/autorenew',
  selectProduct: '/selectproduct',
  liveTrade: '/investmentlivetrade',
  // allLiveTrade: 'investmentlivetrade',
  openLiveTrade: '/investmentlivetrade/opentrade',
  historyLiveTrade: '/investmentlivetrade/historytrade',
  tradeName: '/investmenttradename',
  tradeHistoryName: '/tradehistoryname',
  // allTradeHistory: "alltradehistory",
  allTradeDashboard: '/investmenttradehistory',
  allTrade: '/alltrade',
  sellTrade: '/selltrade',
  buyTrade: '/buytrade'
};

/**
 * Traders urls
 */

export const TradersUrls = {
  freedomPlugin: '/freedom-plugin',
  tradersAccount: '/traders-account'
}

export const NewInvestmentUrls = {
  investmentLive: '/investmentLive',
  investmentDashboard: '/investmentDashboard',
  investmentProducts: '/investmentProducts',
  investmentSilverProducts: '/investmentSilverProducts',
  investmentGoldProducts: '/investmentGoldProducts',
  investmentGoldProductsLive: '/investmentGoldProductsLive',
  investmentPlatinumProducts: '/investmentPlatinumProducts',
  investmentPlatinumProductsLive: '/investmentPlatinumProductsLive',
  investmentMain: '/investment'
};

export const WalletUrls = {
  walletCardManagmentMainScreen: '/walletcardmanagmentmainscreen',
  walletWelcome: '/wallet',
  walletDashboard: '/wallet-dashboard',
  // wallet: "/wallet",
  ManagePayment: '/managepayment',
  makePrimaryBox: '/makeprimarybox',
  cashwithdrawSummary: '/cashwithdrawsummary',
  cashwithdraw: '/cashwithdraw',
  makePrimarysuccessDialog: '/makeprimarysuccessdialog',
  removepaymentdialog: '/removepaymentdialog',
  accountOverview: '/accountoverview',
  cashDeposit: '/cash-deposit',
  cashDepositChild: '/cashdepositchild',
  bankAccount: '/bankaccount',
  creditCard: '/creditcard',
  cryptoCurrency: '/cryptocurrency',
  withdrawRequest: '/withdrawrequest',
  addPrimaryMethod: '/addprimarymethod',
  internalTransfer: '/internaltransfer',
  overviewInternalTransfer: '/overviewinternaltransfer',
  overviewInternalTransferProcessInternalTransfer: '/overviewinternaltransfer/processinternaltransfer',
  transactionsHistory: '/walletdashboard/transactionshistory',
  WalletPaymentMethodRemove: '/walletpaymentmethodremove',
  cashWithdraw: '/cashwithdraw',
  confirmPayment: '/confirmpayment',
  recentActivity: '/recentactivity',
  pendingPayments: '/pendingpayments'
};

export const FriendUrls = {
  allFriendDashboard: '/allfrienddashboard',
  friendDashboard: '/frienddashboard',
  inviteFriends: '/invitefriends',
  allManageFriend: '/allfrienddashboard/managefriends',
  allPendingFriend: '/allfrienddashboard/pendingfriends',
  pendingFriends: 'pendingfriends',
  manageFriends: 'managefriends',
  becomeAffiliate: '/becomeaffiliate'
};

export const EarningandExpenseUrls = {
  earningExpenseDashboard: '/earningexpensedashboard',
  earningSummary: '/earningssummary',
  earningInvestorDashboard: '/maininvestor',
  expenseSummary: '/expensessummary',
  myProductRevenue: '/myproductrevenue',
  directCustomerCommission: '/directcustomercommission',
  directCommission: '/earningsdirectcommission',
  binaryCommission: '/binarycommission',
  matchingBonus: '/matchingbonus',
  fastStartBonus: '/faststartbonus',
  matchingbonuses: 'earningsmatchingbonuses',
  Paycirclecalculations: '/earningsmatchingbonuses/Paycirclecalculations',
  Paycirclecalculationsdetails: '/earningsmatchingbonuses/Paycirclecalculationsdetails/:payCircleId',
  transactions: '/earningsmatchingbonuses/transactions',
  commissions: 'earningscommissions',
  binaryPoint: '/earningscommissions/binarypoint',
  binaryPayout: '/earningscommissions/binarypayout',
  binaryPayoutPaycircleNumber: '/earningscommissions/binarypayoutoverview/:paycircleNumber',
  binaryPayoutrelatedPaycircle: '/earningscommissions/binarypayoutrelatedpaycircle/:relatedPaycircle',
  binaryCommission: '/earningscommissions/binarycommission',
  binaryCommissionpaycircle: '/earningscommissions/binarycommission/paycircle',
  binaryCommissiontransaction: '/earningscommissions/binarycommission/transactions',
  binaryCommissiontransactionid: '/earningscommissions/binarycommission/transactions/:transactionId',
  BinaryCommissionpayCircleDetail: '/earningscommissions/BinaryCommissionpaycircledetail/:payCircleId',
  BinaryCommissionpayoutNumberDetail: '/earningscommissions/BinaryCommissionpayoutnumberdetail/:payoutNumberId',
  suspended: '/earningscommissions/suspended',
  suspendedBinaryPoint: '/earningscommissions/suspended/binarypoint',
  suspendedBinaryCommission: '/earningscommissions/suspended/binarycommission',
  transfer: '/earningscommissions/transfer',
  payoutStatus: '/payoutstatus',
  payoutDashboard: '/payoutdashboard1',
  performanceFee: '/performancefee',
  referralBonus: '/referralbonus',
  notFound: '*'
};

export const PaymentUrls = {
  paymentOption: '/paymentoption',
  managePaymentOptions: '/managepaymentoptions',
  currentCreditCard: '/managepaymentoptions/currentcraditcard',
  currentCryptoProvider: '/managepaymentoptions/currentcryptoprovider',
  currentBankAccount: '/managepaymentoptions/currentbankaccount',
  newCreditCard: '/managepaymentoptions/newcraditcard',
  addCreditCard: '/managepaymentoptions/addcraditcard',
  newCryptoProvider: '/managepaymentoptions/newcryptoprovider',
  newBankAccount: '/managepaymentoptions/newbankaccount',
  editCreditCard: '/managepaymentoptions/editcreditcard',
  payouts: '/payouts',
  payoutDashboard: '/payoutdashboard',
  allPayout: 'allpayout',
  incomingsPayout: '/allpayout/incomingspayout',
  outgoingsPayout: '/allpayout/outgoingsPayout',
  addBankAccount: '/addbankaccount',
  WalletCardManagment: '/walletcardmanagment',
  allLogHistory: 'allloghistory',
  bankLogHistory: '/allloghistory/bankloghistory',
  cardLogHistory: '/allloghistory/cardloghistory',
  transactionHistoryDashboard: 'transactionhistorydashboard',
  allTransactionhistory: '/transactionhistorydashboard/alltransactionhistory',
  acceptedTransaction: '/transactionhistorydashboard/acceptedtransaction',
  rejectedTransaction: '/transactionhistorydashboard/rejectedtransaction'
};

export const SettingUrls = {
  settings: '/settings',
  myInformation: '/settings/myinformation',
  notifications: '/settings/notifications',
  kycRegistration: '/settings/kycregistration',
  onBoardMainScreen: '/onboardmainscreen',
  individualOnBoard: '/settings/individualonboard',
  businessOnBoard: '/settings/companyonboard',
  updateAddress: '/updateaddress',
  updatePassword: '/change-password',
  prefferedSetting: '/prefferedsetting',
  updateProfile: '/updateprofile',
  help: '/help',
  allsettings: 'allsettings',
  information: '/allsettings/information',
  companyInformation: '/allsettings/companyinformation',
  address: '/allsettings/address',
  password: '/allsettings/password',
  document: '/allsettings/document',
  document2: '/allsettings/document2',
  notification: '/allsettings/notification',
  borkers: '/allsettings/brokers',
  // releaseNotes: '/releasenotes',
  updateInformation: '/updateinformation',
  tradersSettings: '/allsettings/traders-settings'
};

export const CustomerSupport = {
  customerSupport: '/customer-support'
}

export const BrokerUrls = {
  listing: '/brokersListing',
  accountDetail: '/brokeraccountDetail',
  selectBroker: '/selectBrokerNew',
  brokerSignup: '/brokerSignup',
  myBroker: '/myBroker',
  brokerMain: '/broker-settings'
};

export const ProductSelectionUrls = {
  productSelection: '/productselection',
  selectionWizard: '/selectionwizard',
  productDashboard: '/productdashboard',
  productSettings: '/productsettings',
  selectBrokerAccounts: '/selectbrokeraccounts',
  summary: '/summary',
  productOverview: '/product-overview',
  simpleSelection: '/simpleselection',
  updateProduct: '/updateproduct',
  updateSimpleSelection: '/updatesimpleselection',
  updateProductSummary: '/updateproductsummary',
  viewAllProducts: '/view-all-products'
};

export const VoucherUrls = {
  voucherDashboard: '/voucherdashboard',
  planVoucher: '/create-voucher',
  upgradeProfile: '/upgrade-profile',
  allVoucher: 'allvoucher',
  myVoucher: '/allvoucher/myvoucher',
  myUsed: '/allvoucher/myvoucher/myused',
  myActive: '/allvoucher/myvoucher/myactive',
  myExpired: '/allvoucher/myvoucher/myexpired',
  sharedVoucher: '/allvoucher/sharedvoucher',
  sharedUsed: '/allvoucher/sharedvoucher/sharedused',
  sharedActive: '/allvoucher/sharedvoucher/sharedactive',
  sharedExpired: '/allvoucher/sharedvoucher/sharedexpired'
};

export const MarketingUrls = {
  marketingDashboard: '/marketingdashboard'
};

export const PepperStone = 'https://pepperstone.com/';

export const LogInAsClientUrls = {
  loginAsClinet: '/loginasclient',
  giveAccessToSupport: '/giveaccesstosupport'
};
