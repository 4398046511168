import axios from 'axios';
import { fontSize, styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import { appInsights } from '../auth/appInsight';
import { getBaseUrl } from '../utils/userConfig';
import useDataJson from '../freedomHooks/useDataJson';
import FreedomGrid from './SharedComponent/FreedomGrid/FreedomGrid';
import { getOTPData } from '../api/DataProvider/walletDataProvider';
import FreedomTimer from './SharedComponent/FreedomTimer/FreedomTimer';
import { devConsoleLog, getOtpValue, getStringProduct } from '../utils/utilityFunctions';
import { paymentOTPStatus } from '../FreedomConstants/freedomConstants';
import FreedomOutlineButton from './SharedComponent/FreedomOutlineButton/FreedomOutlineButton';

/**
 * Styling components
 */
const OTPInput = styled('input')(({ theme, error = false }) => ({
  position: 'relative',
  width: '60px',
  height: '70px',
  fontWeight: 600,
  // color: error && theme.palette.primary.white,
  color: theme.palette.primary.onBoardTextColor,
  background: error ? theme.palette.primary.red : theme.palette.background.default,
  textAlign: 'center',
  border: error ? `2px solid ${theme.palette.primary.red}` : `2px solid ${theme.palette.border.border}`,
  borderRadius: '8px',
  '&:focus': {
    outlineColor: theme.palette.border.border
  },
  '&::placeholder': {
    position: 'absolute',
    color: theme.palette.border.border,
    bottom: '8px',
    right: '8.5px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '48px',
    '&::placeholder': {
      bottom: '5px',
      right: '3px'
    }
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, '16px'),
    width: getStringProduct(3, '60px'),
    height: getStringProduct(3, '70px'),
    border: error ? getStringProduct(3, `2px solid ${theme.palette.primary.red}`) : getStringProduct(3, `2px solid ${theme.palette.border.border}`),
    borderRadius: getStringProduct(3, '8px'),
    '&::placeholder': {
      bottom: getStringProduct(3, '8px'),
      right: getStringProduct(3, '8.5px')
  },
  },
}));
/**
 * Resend Text Style
 */

const OtpTitle = styled('h4')(({ theme, color }) => ({
  fontFamily: "'Poppins'",
  fontStyle: 'normal',
  fontHeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: theme.palette.primary.onBoardTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, '16px'),
    lineHeight: getStringProduct(3, '24px'),
  },

}));

const FreedomOTP = forwardRef(({ firstMessage, secondMessage, showMessage, setActiveStep, systemID, paymentOTPType, PaymentMethodId, setSystemID, setLoading }, ref) => {
  /**
   * States
   */
  const dispatch = useDispatch();
  const theme = useTheme();
  const otpmd = useMediaQuery(theme.breakpoints.up('md'));
  const xl4 = useMediaQuery(theme.breakpoints.up('xl4'));
  const mobileSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { jsonPrefix } = useDataJson();
  const siteUrl = getBaseUrl();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [disableButton, setDisableButton] = useState(true);
  const [runTimer, setRunTimer] = useState(true);
  const [otpLength, setOtpLength] = useState(0);
  const [error, setError] = useState(false);

  const [otp, setOtp] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: ''
  });

  /**
   *
   * Selector
   */
  const { accessToken, userPrincipleName } = useSelector((state) => state.user.user);
  // const email = useSelector((state) => state.user?.userPrincipleName);

  useImperativeHandle(ref, () => ({
    confirmOTP() {
      // debugger;
      if (getOtpValue(otp.otp1, otp.otp2, otp.otp3, otp.otp4, otp.otp5, otp.otp6).length < 6) {
        dispatch(showMessage({ message: 'Invalid OTP', severity: 'info' }));
      } else {
        checkOTP();
      }
    }
  }));

  const otpChangeHander = (event) => {
    devConsoleLog('otpchangehandler');
    setError(false);
    setOtp((pre) => ({
      ...pre,

      [event.target.name]: event.target.value.replace(/\D/g, '')
    }));
  };

  useEffect(() => {
    setOtpLength(getOtpValue(otp.otp1, otp.otp2, otp.otp3, otp.otp4, otp.otp5, otp.otp6));
    if (otpLength.length === 5) {
      devConsoleLog('nothing happen');
    }
  }, [otp.otp1, otp.otp2, otp.otp3, otp.otp4, otp.otp5, otp.otp6, otpLength]);

  /**
   * function for auto focus
   */
  const inputfocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      // devConsoleLog("next");
      const next = elmnt.target.tabIndex;
      if (next < 6 && elmnt.target.value !== '') {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const startCountDown = () => {
    setDisableButton(true);
    setRunTimer(true);
  };

  /**
   * Resend OTP
   */
  const reGenrateOTP = async () => {
    await getOTPData('2222', paymentOTPType, systemID, paymentOTPStatus.Resend, accessToken)
      .then(function (_res) {
        // handle success

        if (_res === undefined) {
          dispatch(showMessage({ message: 'please check your internet connection', severity: 'error' }));
          return;
        }

        if (_res?.response?.status === 500) {
          devConsoleLog('response from otp', _res?.response);
          dispatch(showMessage({ message: 'Error occoured during OTP ReGenrating', severity: 'error' }));
          return;
        }

        devConsoleLog(_res);
        if (_res?.data) {
          dispatch(showMessage({ message: 'Code sent to your email address', severity: 'info' }));
          startCountDown();
        } else {
          dispatch(showMessage({ message: 'Code sent to your email address', severity: 'info' }));
          startCountDown();
        }
      })
      .catch(function (error) {
        // handle error
        appInsights.trackException({ error: new Error(error) });

        devConsoleLog(error?.response);
        dispatch(showMessage({ message: 'Error occoured during OTP ReGenrating', severity: 'error' }));
      });
  };

  const cardPayload = async () => {
    let payload = [
      {
        __metadata: { type: 'Users' },
        ...jsonPrefix,
        Id: '@UserID',
        ModifiedByUserId: '@UserID',
        PrimaryPaymentMethodType: paymentOTPType,
        PrimaryPaymentMethodId: PaymentMethodId
      }
    ];
    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);

    axios({
      url: siteUrl,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(function (response) {
        if (response?.data) {
          setSystemID(response?.data);
          dispatch(showMessage({ message: 'Code sent to your E-mail', severity: 'info' }));
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          devConsoleLog(response.data);
        } else {
          // dispatch(showMessage({ message: 'No response found', severity: 'info' }));
        }
      })
      .catch(function (error) {
        appInsights.trackException({ error: new Error(error) });

        devConsoleLog(error?.response);
        if (error === undefined) {
          dispatch(showMessage({ message: 'Please check your internet connection', severity: 'error' }));
        }
        setActiveStep(0);
        setLoading(false);
      });
  };

  /**
   * OTP Validate API call
   */
  const checkOTP = async () => {
    await getOTPData(otpLength, paymentOTPType, systemID, paymentOTPStatus.Validate, accessToken)
      .then(function (_res) {
        // handle success
        if (_res === undefined) {
          dispatch(showMessage({ message: 'Please check your internet connection', severity: 'error' }));

          return;
        }

        if (_res?.response?.status === 500) {
          devConsoleLog('response from otp', _res?.response);
          setError(true);
          return;
        }

        devConsoleLog('_res', _res);
        if (_res !== undefined && _res?.data) {
          dispatch(showMessage({ message: firstMessage || 'OTP sent to your mail.', severity: 'success' }));

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          devConsoleLog('confirm');
          cardPayload();
          dispatch(showMessage({ message: secondMessage || 'Successfully Added.', severity: 'success' }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setLoading(false);
        }
      })
      .catch(function (error) {
        // handle error
        appInsights.trackException({ error: new Error(error) });

        devConsoleLog('catch', error);
        if (error?.response) setError(true);
        dispatch(showMessage({ message: 'Error occoured during OTP checking.', severity: 'error' }));
      });
  };

  try {
    return (
      <React.Fragment>
        <form>
          <FreedomGrid spacing={otpmd ? 4 : 1} justifyContent={'center'}>
            <Grid item xs={12}>
              <OtpTitle>Please enter the 6 digit code {userPrincipleName}</OtpTitle>
            </Grid>
            <Grid item>
              <OTPInput name="otp1" type="text" autoComplete="off" value={otp.otp1} onChange={otpChangeHander} tabIndex="1" maxLength="1" onKeyUp={(e) => inputfocus(e)} autoFocus={true} error={error} placeholder={isSmallScreen ? '____' : '_____'} />
            </Grid>
            <Grid item>
              <OTPInput name="otp2" type="text" autoComplete="off" value={otp.otp2} onChange={otpChangeHander} tabIndex="2" maxLength="1" onKeyUp={(e) => inputfocus(e)} error={error} placeholder={isSmallScreen ? '____' : '_____'} />
            </Grid>
            <Grid item>
              <OTPInput name="otp3" type="text" autoComplete="off" value={otp.otp3} onChange={otpChangeHander} tabIndex="3" maxLength="1" onKeyUp={(e) => inputfocus(e)} error={error} placeholder={isSmallScreen ? '____' : '_____'} />
            </Grid>
            <Grid item>
              <OTPInput name="otp4" type="text" autoComplete="off" value={otp.otp4} onChange={otpChangeHander} tabIndex="4" maxLength="1" onKeyUp={(e) => inputfocus(e)} error={error} placeholder={isSmallScreen ? '____' : '_____'} />
            </Grid>
            <Grid item>
              <OTPInput name="otp5" type="text" autoComplete="off" value={otp.otp5} onChange={otpChangeHander} tabIndex="5" maxLength="1" onKeyUp={(e) => inputfocus(e)} error={error} placeholder={isSmallScreen ? '____' : '_____'} />
            </Grid>
            <Grid item>
              <OTPInput
                name="otp6"
                type="text"
                autoComplete="off"
                // className='otpInput'
                value={otp.otp6}
                onChange={otpChangeHander}
                tabIndex="6"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
                error={error}
                placeholder={ isSmallScreen ? '____' : '_____'}
              />
            </Grid>
            <Grid container mt={xl4 ? 3 : 1} spacing={xl4 ? 6 : 2} direction={'column'} alignItems={'center'}>
              {error && (
                <Box
                  sx={{
                    color: theme.palette.primary.red,
                    textAlign: 'left',
                    [theme.breakpoints.up('xl4')]: {
                      fontSize: getStringProduct(3, '16px')
                    }
                  }}
                >
                  Wrong OTP
                </Box>
              )}
            </Grid>
            <Grid
              container
              mt={xl4 ? 6 : 2}
              // spacing={2}
              direction={'column'}
              alignItems={'center'}
            >
              {disableButton ? (
                <Grid item>
                  <FreedomTimer startTime={runTimer} disableButton={setDisableButton} />
                </Grid>
              ) : (
                <Grid item container justifyContent={'center'} spacing={mobileSm ? 5 : 27}>
                  <Grid item mt={xl4 ? 4.5 : mobileSm ? 1 : 1.5}>
                    <OtpTitle>Didn’t received code?</OtpTitle>
                  </Grid>
                  <Grid item>
                    <FreedomOutlineButton padding={'10px'} smpadding={'5px'} fontweight={'500'} buttonborder={`1px solid ${theme.palette.border.border}`} buttoncolor={theme.palette.primary.onBoardTextColor} onClick={reGenrateOTP}>
                      Resend
                    </FreedomOutlineButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </FreedomGrid>
        </form>
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
});

export default FreedomOTP;
