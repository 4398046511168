import axios from 'axios';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Box, Grid, Step, StepContent, Stepper, useMediaQuery, useTheme } from '@mui/material';

import { VoucherUrls } from '../url/Links';
import useForm from '../freedomHooks/useForm';
import { appInsights } from '../auth/appInsight';
import { getBaseUrl } from '../utils/userConfig';
import FreedomOTP from '../Components/FreedomOTP';
import useAddress from '../CustomHooks/useAddress';
import { images, voucherSvg } from '../url/SvgLinks';
import useDataJson from '../freedomHooks/useDataJson';
import { getUserBalance } from '../api/apiUrls/apiUrl';
import AccountCard from './UpgradeProfile/AccountCard';
import PaymentCard from './UpgradeProfile/PaymentCard';
import useStyle, { ColorlibStepIconRoot } from '../Components/Style';
import { accountUpGradeUrl } from '../api/apiUrls/accountUpgradeApiUrl';
import { updatehideBottombar } from '../features/affiliate/customerSlice';
import FreedomSnackBar from '../Components/SharedComponent/FreedomSnackBar';
import AddressDialog from '../Settings/MyInformation/Template/AddressDialog';
import { selectSnackbar, showSnackbar } from '../features/snackbar/snackbarSlice';
import FreedomAlert from '../Components/SharedComponent/FreedomAlert/FreedomAlert';
import ErrorDialogBox from '../Components/SharedComponent/ErrorDialogBox/ErrorDialogBox';
import FreedomButton1 from '../Components/SharedComponent/FreedomButton1/FreedomButton1';
import FreedomButton2 from '../Components/SharedComponent/FreedomButton2/FreedomButton2';
import ImportantNotes from '../Components/SharedComponent/ImportantNotes/ImportantNotes';
import { formatExpirationDate } from '../Wallet/ManagePaymentOptions/utils/utilFunctions';
import { getStripConfirmPaymentUrl, getStripPaymentUrl } from '../api/apiUrls/walletApiUrl';
import FreedomCreditCard from '../Components/SharedComponent/FreedomCreditCard/FreedomCreditCard';
import { orderTypes, paymentAccountType, paymentOTPType } from '../FreedomConstants/freedomConstants';
import FreedomFollowUpIcon from '../Components/SharedComponent/FreedomFollowUpIcon/FreedomFollowUpIcon';
import FreedomColorDivider from '../Components/SharedComponent/FreedomColorDivider/FreedomColorDivider';
import FreedomSuccessImage from '../Components/SharedComponent/FreedomSuccessImage/FreedomSuccessImage';
import { FreedomRadioButton1 } from '../Components/SharedComponent/FreedomRadioButton1/FreedomRadioButton1';
import FreedomTypographyText from '../Components/SharedComponent/FreedomTypographyText/FreedomTypographyText';
import InsufficientBalancePopup from '../Components/SharedComponent/InsufficientBalancePopup/InsufficientBalancePopup';
import { FixedAfterTwoDigit1, RemoveCommaAndDigit, apiErrorResponses, capitalizeFullName, currentDate, currentTime24hr, devConsoleLog, getDynamicBorder, getStringProduct } from '../utils/utilityFunctions';
import StepperLable from './UpgradeProfile/UpgradeProfileSteps/components/StepperLable';
import VoucherFirstStep from './VoucherSteps/VoucherFirstStep';
import VoucherSecondStep from './VoucherSteps/VoucherSecondStep';
import YoutubeVideo from '../Components/youtubevideo/YoutubeVideo';
import { voucherVideoLinks } from '../utils/videosUrl';
import { PageTitle } from '../Components/SharedComponent/FreedomStyledComponents/FreedomStyledComponents';
import usePageTitle from '../CustomHooks/usePageTitle';

const BlackCheckIcon = images.BlackCheckIcon;
const CircleBlackfilled = images.CircleBlackfilled;
const infoIcon = voucherSvg.importantNotes;
const copyIcon = images.copyIcon;

const Title = styled('div')(({ theme, fontweight, fontsize, lineheight, textalign, color }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || '400',
  fontSize: fontsize || '13px',
  lineHeight: lineheight || '18px',
  color: color || theme.palette.primary,
  whiteSpace: 'nowrap',
  width: '70%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: textalign || 'center',
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, fontsize || '13px'),
    lineHeight: getStringProduct(3, lineheight || '18px'),
  }
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CheckIcon />,
    2: <CheckIcon />,
    3: <CheckIcon />,
    4: <CheckIcon />,
    5: <CheckIcon />
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
};
const steps1 = [
  {
    id: 1,
    label: 'Select Voucher'
  },
  {
    id: 2,
    label: 'Select Payment Method'
  },
  {
    id: 3,
    label: 'Verification Code'
  },
  {
    id: 4,
    label: 'Payment Summary',
    label1: 'Freedom Bank Details'
  },
  {
    id: 5,
    label: 'Voucher Created'
  }
];

export const VoucherWrapper = styled('div')(({ theme, bgcolor, padding, height, margin, flexDirection, borderRadius, bordercolor, justifyContent, alignItems, width }) => ({
  border: bordercolor ? `2px solid ${bordercolor}` : `2px solid ${theme.palette.border.containerborder}`,
  margin: margin || '0px',
  padding: padding || '5px',
  gap: 5,
  borderRadius: borderRadius || '10px',
  background: bgcolor || '',
  height: height || '',
  width: width || '100%',
  display: 'flex',
  flexDirection: flexDirection || 'column',
  justifyContent: justifyContent,
  alignItems: alignItems || 'center',
  [theme.breakpoints.up('xl4')]: {
    border: bordercolor ? getDynamicBorder(3, `2px solid ${bordercolor}`) : getDynamicBorder(3, `2px solid ${theme.palette.border.containerborder}`),
    margin: getStringProduct(3, margin || '0px'),
    padding: getStringProduct(3, padding || '5px'),
    gap: 15,
    borderRadius: getStringProduct(3, borderRadius || '10px'),
    height: getStringProduct(3, height || ''),
    width: getStringProduct(3, width || '100%'),
    }
}));

export const PlanVoucherStepWrapper = styled(Box)(({ theme }) => ({
  padding: '10px',
  height: 'calc(100vh - 360px)',
  border: `2px solid ${theme.palette.border.containerborder}`,
  borderRadius: '10px',
  '::-webkit-scrollbar': {
    width: 0 /* Remove scrollbar space */,
    background: 'transparent' /* Optional: just make scrollbar invisible */
  },
  overflow: 'auto',
  // [theme.breakpoints.up('xlll')]: {
  //   height: 'calc(100vh - 500px)'
  // },
  [theme.breakpoints.up('xl4')]: {
    padding: getStringProduct(3, '10px'),
    height: 'calc(100vh - 1080px)',
    border: getStringProduct(3, `2px solid ${theme.palette.border.containerborder}`),
    borderRadius: getStringProduct(3, '10px'),
    },
}));
const siteUrl = getBaseUrl();

//Our Component Start from here
const PlanVoucher = () => {
  const { userName: email, transactionFee } = useSelector((state) => state.user?.user);
  // const transactionFee = useSelector((state) => state.user.transactionFee);

  const classes = useStyle();
  const theme = useTheme();
  const xl4 = useMediaQuery((theme) => theme.breakpoints.up('xl4'))
  const stripe = useStripe();
  const elements = useElements();
  const { jsonPrefix } = useDataJson();
  const confirmOTP = useRef(null);
  //Our Component Local State
  const [loading, setLoading] = useState(false);
  const [systemID, setSystemID] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [IsError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [paymentPayMethod, setPaymentPayMethod] = useState(1);
  const [steps, setSteps] = useState(steps1);
  const [cards, setCards] = useState([]);
  const [openAlertMessage, setOpenAlertMessage] = useState('');
  const [opentAlert, setOpenAlert] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(false);
  let stepForCreditCard = {
    id: 3,
    label: 'Enter Card Details'
  };
  let stepForInternalAccount = {
    id: 3,
    label: 'Verification Code'
  };

  const [selectedVoucher, setSelectdeVoucher] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector(selectSnackbar);

  const { accessToken, fullName: username } = useSelector((state) => state.user.user);

  //useForm Hook
  const { form, handleChange, resetForm, setForm } = useForm({
    cardNumberAlready: '',
    accountNumberAlready: '',
    BillingAddressId: '',
    cardNumber: '',
    cardNumberMasked: '',
    cvc: '',
    cvvmasked: '',
    expMonth: '',
    expYear: '',
    cardExpiryDate: '',
    IsSystemCard: '',
    cardId: '',
    cardHolderName: '',
    accountHolderName: '',
    iban: '',
    bankName: '',
    accountName: '',
    swiftCode: '',
    accountNumber: '',
    cashDepositMethod: '',
    amount: '',
    label: '',
    voucherName: '',
    voucherPrice: '',
    VoucherExpiryDate: '',
    accountTypeId: '',
    selected: 'false',
    id: 0,
    description: ''
  });

  const address = useAddress();
  const [voucherArr, setVoucherArr] = useState([]);
  const [addAddressDialog, setAddAddressDialog] = useState(false);
  const [balance, setBalance] = useState(0);

  //useTranslation for changing Language
  const { t } = useTranslation();
  const {pageTitle} = usePageTitle()
  
  const getAccountbalance = useCallback(
    async (id) => {
      setLoading(true);
      await axios
        .get(getUserBalance(id), {
          headers: { Authorization: 'Bearer ' + accessToken }
        })
        .then((res) => {
          // setBalance(0)
          setBalance(res?.data[0]?.balance);
          // setBalance(10);
          devConsoleLog('response from balance', res);
        })
        .catch((error) => {
          devConsoleLog(error);
          appInsights.trackException({ error: new Error(error) });

          dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
          setLoading(false);
        });
      setLoading(false);
    },
    [balance]
  );

  const getVouchers = async () => {
    await axios
      .get(accountUpGradeUrl(), {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      // getAccountUpGradeData(accessToken)
      .then((_res) => {
        devConsoleLog('response from account upgrade', _res.data);
        let Array = [];
        if (_res?.data?.length > 0) {
          _res?.data?.forEach((item, index) => {
            if (item?.Id !== 1) {
              Array.push({
                id: item?.Id,
                voucherName: item?.Name,
                VoucherPrice: item?.UpgradePrice,
                accountTypeId: item?.Id,
                expiryDate: '01 Year',
                selected: false,
                // description: `${index}Stay ahead of the competition with early bidding opportunities. Have a direct line of communication with Freedom's management and executives for real-time feedback and advice.`,
                description: item?.Features
              });
            }
          });
          setVoucherArr(Array);
        }
      })
      .catch((error) => {
        devConsoleLog(error);

        appInsights.trackException({ error: new Error(error) });

        dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
      });
  };

  useEffect(() => {
    getAccountbalance(1001);
    getVouchers();
    // dispatch(updatehideBottombar(true));
  }, []);

  const ChangeVoucher = useCallback(
    (item) => {
      devConsoleLog('selected item', item);
      setForm({
        ...form,
        voucherName: item.voucherName,
        voucherPrice: item.VoucherPrice,
        VoucherExpiryDate: item.VoucherExpiryDate,
        accountTypeId: item.AccountTypeID,
        selected: true,
        id: item.id,
        description: item.description
      });
      setSelectdeVoucher(item.id);
    },
    [form, selectedVoucher]
  );
  
  const handleNext = (ev) => {
    if (activeStep === steps.length - 1) {
      //this step use for Download Receipt in future
      dispatch(updatehideBottombar(false));
      navigate(VoucherUrls.voucherDashboard);
    } else if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (paymentPayMethod == paymentAccountType.creditCardAccount) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.internalAccount) {
        if (balance < +form.voucherPrice + +transactionFee) {
          setOpenDialog(true);
        } else {
          postuserOTPRequests(paymentAccountType.internalAccount);
          setHideBackButton(true);
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (paymentPayMethod == paymentAccountType.bankAccount) {
        postuserOTPRequests(paymentAccountType.bankAccount);
        setHideBackButton(true);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      if (paymentPayMethod == paymentAccountType.creditCardAccount) {
        handleSubmitStripeRequest(ev);
        setHideBackButton(true);
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.internalAccount) {
        confirmOTP.current.confirmOTP();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (paymentPayMethod == paymentAccountType.bankAccount) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      dispatch(updatehideBottombar(false));
      navigate(VoucherUrls.voucherDashboard);
    }
    if (activeStep === 3) {
      dispatch(updatehideBottombar(false));
      navigate(VoucherUrls.voucherDashboard);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const postuserOTPRequests = (paymentType) => {
    let voucherFeeAmount = 0;
    if (paymentType == paymentAccountType.bankAccount) {
      voucherFeeAmount = +form.voucherPrice + +transactionFee;
    } else {
      voucherFeeAmount = form.voucherPrice;
    }
    setLoading(true);

    let payload = [
      {
        __metadata: { type: 'UserOrderRequests' },
        ...jsonPrefix,
        Id: 0,
        CreatedByUserId: '@UserId',
        ModifiedByUserId: '@UserId',
        OrderTypeId: orderTypes.voucher,
        UserID: '@UserId',
        PaymentMethodId: paymentType,
        Amount: voucherFeeAmount,
        UserOrderLineRequests: [
          {
            Id: 0,
            OrderId: '@OrderId',
            VoucherID: '@VoucherID',
            // Expiry: utc,
            AccountTypeID: selectedVoucher,
            Amount: voucherFeeAmount
          }
        ]
      }
    ];

    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);
    devConsoleLog('secondJSON', secondJSON);
    axios({
      url: siteUrl,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(function (response) {
        // devConsoleLog("response", response)
        if (response?.data) {
          setSystemID(response?.data);
          if (paymentType == paymentAccountType.bankAccount) {
            setOpenDialog(true);
          }
          if (paymentType == paymentAccountType.internalAccount) {
            dispatch(showSnackbar({ message: 'Code Sent To Your Mobile Device or Email.', severity: 'info' }));
          }
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error) });
        dispatch(showSnackbar({ message: `${t(apiErrorResponses(error))}`, severity: 'error' }));
        setErrorMessage(error?.response?.data);
        setIsError(true);
        setLoading(false);
        setActiveStep(0);
      });
  };

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmitStripeRequest = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setHideBackButton(false);
      return;
    }
    if (form.voucherPrice === '') {
      setHideBackButton(false);
      return;
    }

    var formattedValue = form.voucherPrice.toLocaleString().split('.')[0];
    formattedValue = formattedValue.toLocaleString().replace(/\D/g, '');
    devConsoleLog('amount to be added', +formattedValue + +transactionFee, RemoveCommaAndDigit(formattedValue));
    let payload = {
      ...jsonPrefix,
      StripePaymentTypeID: 1,
      OrderTypeId: orderTypes.voucher,
      AccountTypeID: selectedVoucher,
      Amount: +form.voucherPrice + +transactionFee
    };

    const myJSON = JSON.stringify(payload);
    const secondJSON = JSON.stringify(myJSON);
    devConsoleLog('strip jeson for vocher create', secondJSON);
    axios({
      url: getStripPaymentUrl(),
      headers: {
        'content-type': 'application/json; charset=utf-8',
        Authorization: 'Bearer ' + accessToken
      },
      method: 'post',
      data: secondJSON
    })
      .then(async (res) => {
        devConsoleLog('response', res);
        let cardExpiry = {};
        let card = {};
        const { client_secret: clientSecret } = await res.data;

        // Confirm the PaymentIntent using the details collected by the Payment Element
        const payload = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: 'http://localhost:3000'
          },
          redirect: 'if_required'
        });
        devConsoleLog('payload payment', payload);
        if (payload.paymentIntent && payload.paymentIntent.status === 'succeeded') {
          await axios
            .post(getStripConfirmPaymentUrl(), JSON.stringify(payload.paymentIntent.id), {
              headers: {
                accept: '*/*',
                'Content-Type': 'application/json;odata.metadata=minimal;odata.streaming=true',
                Authorization: `Bearer ${accessToken}`
              }
            })

            .then(async (res) => {
              devConsoleLog('response12', res);
              card = res?.data?.card;

              if (res?.data?.card?.expMonth >= 1 && res?.data?.expMonth <= 9) {
                card.expiryMonth = res?.data?.card?.expMonth.toString().padStart(2, '0');
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              } else {
                card.expiryMonth = res?.data?.card?.expMonth?.toString();
                card.expiryYear = res?.data?.card?.expYear.toString().slice(-2);
                card.expiry = `${card.expMonth}/${card.expYear}`;
              }
              devConsoleLog('card check', card);
              setCards(card);
              setLoading(false);
              //  alert("success");

              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((error) => {
              devConsoleLog('err', error?.response);
              setOpenAlertMessage(error?.response);
              setHideBackButton(false);
              setOpenAlert(true);
              setLoading(false);
            });
        } else {
          if (payload.error) {
            // setErrorMessage(payload.error.message);
            setOpenAlertMessage(payload.error.message);
            setHideBackButton(false);
            setOpenAlert(true);
            // setIsError(true);
            devConsoleLog('payment error', payload.error.message);
            setLoading(false);
            devConsoleLog('err1', payload.error);

            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            // handleError(payload.error);
          } else {
            // setLoading(false)
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }
      })
      .catch((error) => {
        devConsoleLog('err', error?.response);
        devConsoleLog('err', error);

        setLoading(false);
        if (error?.response) setErrorMessage(error?.response?.data);
        setIsError(true);
      });
  };

  //Summary
  const hanldeSteperAdd = (newPaymentPayMethod) => {
    let exsitingTempSteps = steps;
    if (steps.length === 5) {
      exsitingTempSteps = [];
      steps.forEach((item, index) => {
        if (index === 2) {
          return 0;
        } else {
          exsitingTempSteps.push(item);
        }
      });
    }
    if (exsitingTempSteps.length !== 5) {
      let tempSteps = [];
      exsitingTempSteps.forEach((item, index) => {
        if (index === 2) {
          if (newPaymentPayMethod == paymentAccountType.creditCardAccount) {
            tempSteps.push(stepForCreditCard, item);
          }
          if (newPaymentPayMethod == paymentAccountType.internalAccount) {
            tempSteps.push(stepForInternalAccount, item);
          }
        } else {
          tempSteps.push(item);
        }
      });
      setSteps(tempSteps);
    }
  };

  const hanldeSteperMinus = (newPaymentPayMethod) => {
    if (steps.length === 5) {
      let tempSteps = [];
      steps.forEach((item, index) => {
        if (index === 2) {
          return 0;
        } else {
          tempSteps.push(item);
        }
      });
      setSteps(tempSteps);
    }
  };
  const handleChangeRadioButtonForPaymentMethod = (event) => {
    if (event.target.value == paymentAccountType.internalAccount) {
      setPaymentPayMethod(paymentAccountType.internalAccount);
      hanldeSteperAdd(paymentAccountType.internalAccount);
    } else if (event.target.value == paymentAccountType.creditCardAccount) {
      setPaymentPayMethod(paymentAccountType.creditCardAccount);
      hanldeSteperAdd(paymentAccountType.creditCardAccount);
    } else if (event.target.value == paymentAccountType.bankAccount) {
      setPaymentPayMethod(paymentAccountType.bankAccount);
      hanldeSteperMinus(paymentAccountType.bankAccount);
    }
  };
  const SummaryComp = (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid container spacing={1} item xs={12} sm={12} md={10} lg={10} xl={10}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <VoucherWrapper height={'100%'}>
            <VoucherWrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              <PaymentCard paymentPayMethod={paymentPayMethod} username={username} />
            </VoucherWrapper>
            <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'} bordercolor={theme.palette.border.border} bgcolor={paymentPayMethod === paymentAccountType.internalAccount ? theme.palette.button.buttonbgcolor : ''}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FreedomRadioButton1
                  margin={'0px 4px 0px 0px'}
                  checked={paymentPayMethod === paymentAccountType.internalAccount}
                  width={24}
                  height={24}
                  onClick={handleChangeRadioButtonForPaymentMethod}
                  value={paymentAccountType.internalAccount}
                  name="id"
                />
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400} color={paymentPayMethod === paymentAccountType.internalAccount && theme.palette.primary.white}>
                  {' '}
                  Internal Account{' '}
                </FreedomTypographyText>
              </Box>
              <Box>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'16px'} color={paymentPayMethod === paymentAccountType.internalAccount && theme.palette.primary.white} fontweight={700}>
                  {' '}
                  ${FixedAfterTwoDigit1(balance)}
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
            <VoucherWrapper flexDirection={'row'} padding={'10px'} bordercolor={theme.palette.border.border} bgcolor={paymentPayMethod === paymentAccountType.creditCardAccount ? theme.palette.button.buttonbgcolor : ''}>
              <FreedomRadioButton1
                margin={'0px 4px 0px 0px'}
                checked={paymentPayMethod === paymentAccountType.creditCardAccount}
                width={24}
                height={24}
                onClick={handleChangeRadioButtonForPaymentMethod}
                value={paymentAccountType.creditCardAccount}
                name="id"
              />
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400} color={paymentPayMethod === paymentAccountType.creditCardAccount && theme.palette.primary.white}>
                {' '}
                Card Payment{' '}
              </FreedomTypographyText>
            </VoucherWrapper>
            {/* <VoucherWrapper
                            flexDirection={'row'}
                            justifyContent={"space-between"}
                            padding={"10px"}
                            alignItems={"center"}
                            bordercolor={theme.palette.border.border}
                            bgcolor={paymentPayMethod === paymentAccountType.bankAccount ? theme.palette.button.buttonbgcolor : ""}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <FreedomRadioButton1
                                    margin={"0px 4px 0px 0px"}
                                    checked={paymentPayMethod === paymentAccountType.bankAccount}
                                    width={24}
                                    height={24}
                                    onClick={handleChangeRadioButtonForPaymentMethod}
                                    value={paymentAccountType.bankAccount}
                                    name='id'
                                />
                                <FreedomTypographyText
                                    fontFamily="Poppins"
                                    fontsize={"12px"}
                                    fontweight={400}
                                    color={paymentPayMethod === paymentAccountType.bankAccount && theme.palette.primary.white}
                                > Bank </FreedomTypographyText>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <FreedomTypographyText
                                    lineheight={"12px"}
                                    fontFamily="Poppins"
                                    fontsize={"8px"}
                                    fontweight={400}
                                    color={paymentPayMethod === paymentAccountType.bankAccount && theme.palette.primary.white}

                                > Transaction Time </FreedomTypographyText>
                                <FreedomTypographyText
                                    lineheight={"12px"}
                                    fontFamily="Poppins"
                                    fontsize={"10px"}
                                    fontweight={600}
                                    color={paymentPayMethod === paymentAccountType.bankAccount && theme.palette.primary.white}

                                >  5 - 8 days</FreedomTypographyText>
                            </Box>
                        </VoucherWrapper> */}
          </VoucherWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <VoucherWrapper height={'100%'}>
            <VoucherWrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              <AccountCard accountType={selectedVoucher - 1} price={form.voucherPrice} title={form.voucherName} />
            </VoucherWrapper>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Voucher - {form.voucherName}{' '}
              </FreedomTypographyText>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                {' '}
                ${FixedAfterTwoDigit1(+form.voucherPrice)}{' '}
              </FreedomTypographyText>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Freedom Transaction Fee{' '}
              </FreedomTypographyText>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                {' '}
                ${FixedAfterTwoDigit1(+transactionFee)}
              </FreedomTypographyText>
            </VoucherWrapper>
            <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'} bordercolor={theme.palette.border.border} alignItems={'center'}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged =(Voucher + Fee)
                </FreedomTypographyText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+(form.voucherPrice + +transactionFee))}
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <VoucherWrapper height={'100%'} padding={'0px'}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.paymentdetailbg,
                width: '100%',
                display: 'flex',
                padding: '8px',
                borderTopRightRadius: '11px',
                borderTopLeftRadius: '11px'
              }}
            >
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={600} color={theme.palette.primary.white}>
                {' '}
                Payment Details{' '}
              </FreedomTypographyText>
            </Box>
            <VoucherWrapper height={'100%'} bordercolor={'none'}>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Email{' '}
                </FreedomTypographyText>
                <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                  {email}
                </Title>
                {/* <Box style={{
                                    width: '50%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>
                                    <FreedomTypographyText
                                        fontFamily="Poppins"
                                        fontsize={"12px"}
                                        fontweight={600}
                                    > {email} </FreedomTypographyText>
                                </Box> */}
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Time{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentTime24hr()}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentDate()}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Voucher Product Expiry Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  1 Year{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Payment Method{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {paymentPayMethod === paymentAccountType.internalAccount ? 'Internal Account' : paymentPayMethod === paymentAccountType.creditCardAccount ? 'Card Payment' : paymentPayMethod === paymentAccountType.bankAccount ? 'Bank' : ''}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
      </Grid>
    </Grid>
  );

  const summarySteps = [
    {
      label: 'Transfer Money',
      description: `Transfer your money to Freedom account.`
    },
    {
      label: 'Process Order ',
      description: 'Wait 1-2 business days for local transfers and 4-5 days for international transfers.'
    },
    {
      label: 'Funds Arrived',
      description: `Receive your deposit amount `
    }
  ];

  const impNotes = [
    {
      description: 'The receiver/Payee/Beneficiary name must be FREEDOM'
    },
    {
      description: 'The name you use must match the name registered to your FREEDOM account      '
    },
    {
      description: `During the transfer mentioned the 'Reference Number' provided by Freedom with its bank details`
    },
    {
      description: 'Please note that if your bank is based outside of Dubai, there might be additional fee involved, please get in touch with your bank      '
    }
  ];

  const BankDetial = (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} justifyContent="center">
      <ImportantNotes openDialog={openDialog} setOpenDialog={setOpenDialog} heading="Important Notes">
        <ul
          style={{
            marginLeft: '0'
          }}
        >
          {' '}
          {impNotes.map((note, index) => (
            <li key={index}>
              <FreedomTypographyText fontsize={'12px'} lineheight={'32px'} fontweight={500} textalign="left" margin={'0px'}>
                {note.description}
              </FreedomTypographyText>
            </li>
          ))}
        </ul>
      </ImportantNotes>
      <Grid item xs={12} md={4}>
        <VoucherWrapper height={'100%'} padding={'16px'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px'
            }}
          >
            <FreedomTypographyText color={theme.palette.primary.white} fontsize="16px" fontweight={600} textalign="left" style={{ marginBottom: '25px', marginLeft: '12px' }}>
              How Does It Works?
            </FreedomTypographyText>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={CircleBlackfilled} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[0].label}
              </FreedomTypographyText>
            </Box>
            <Box
              sx={{
                width: '100%',
                maxHeight: '48px',
                marginLeft: '5px',
                borderLeft: `1px dashed ${theme.palette.primary.main}`
              }}
            >
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[0].description}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={CircleBlackfilled} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[1].label}
              </FreedomTypographyText>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '48px',
                marginLeft: '5px',
                borderLeft: `1px dashed ${theme.palette.primary.main}`
              }}
            >
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[1].description}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img src={BlackCheckIcon} style={{ width: '12px', height: '12px' }} alt="img" />
              <FreedomTypographyText fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 8px'}>
                {summarySteps[2].label}
              </FreedomTypographyText>
            </Box>
            <Box sx={{ width: '100%', height: '48px', marginLeft: '5px' }}>
              <FreedomTypographyText color={theme.palette.primary.placeholder} fontsize={'12px'} lineheight={'18px'} fontweight={500} textalign="left" margin={'0px 0px 0px 12px'}>
                {summarySteps[2].description}
              </FreedomTypographyText>
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.importantNote,
              border: `1px solid ${theme.palette.border.border}`,
              borderRadius: '5px',
              height: '36px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
              mt: 3,
              width: '100%'
            }}
          >
            <FreedomTypographyText fontsize="12px" color={theme.palette.primary.onBoardTextColor}>
              Important Notes
            </FreedomTypographyText>

            <FreedomFollowUpIcon
              padding="4px"
              height="28px"
              width="28px"
              responsiveheight="28px"
              responsivewidth="28px"
              borderradius="5px"
              // bordercolor='none'
              backgroundcolor={theme.palette.background.default}
              // backgroundhovercolor={theme.palette.icon.iconColor}
              // iconimg={mode === 'light' ? infoIconLight : infoIconLight}
              iconimg={infoIcon}
              onClick={() => setOpenDialog((prev) => !prev)}
            />
          </Box>
        </VoucherWrapper>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
        <VoucherWrapper height={'100%'} padding={'0px'}>
          <VoucherWrapper height={'100%'} bordercolor={'none'}>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Deposited Currency{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  USD{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('USD');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Account Name{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  MyFreedom L.L.C-FZ{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('MyFreedom L.L.C-FZ');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Account Number
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  9946375812{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('9946375812');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Bank Name{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  Wio Bank PJSC{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('Wio Bank PJSC');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Bank Address{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'75%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  Etihad Airways Centre 5th Floor, Abu Dhabi, UAE{' '}
                </FreedomTypographyText>
                <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} margin={'0px'} width={'20px'}>
                  <FreedomFollowUpIcon
                    tooltiptitle={'Copy'}
                    padding="2px"
                    height="16px"
                    width="16px"
                    responsiveheight="16px"
                    responsivewidth="16px"
                    borderradius="5px"
                    bordercolor="none"
                    iconimg={copyIcon}
                    onClick={async () => {
                      await navigator.clipboard.writeText('Etihad Airways Centre 5th Floor, Abu Dhabi, UAE');
                    }}
                  />
                </VoucherWrapper>
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                IBAN{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  AE210860000009946375812{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('AE210860000009946375812');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                BIC/SWIFT{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  WIOBAEADXXX{' '}
                </FreedomTypographyText>
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText('WIOBAEADXXX');
                  }}
                />
              </VoucherWrapper>
            </VoucherWrapper>
            <Box sx={{ width: '100%' }}>
              <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'0px'} marginBottom={'0px'} />
            </Box>
            <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'8px'}>
              <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                {' '}
                Freedom Reference No.{' '}
              </FreedomTypographyText>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'flex-end'} padding={'0px'} width={'50%'}>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600} margin={'0px 4px'}>
                  {' '}
                  {systemID}{' '}
                </FreedomTypographyText>
                {/* <Tooltip
                                    enterTouchDelay={0}
                                    title='copy'
                                    placement='top-start'>
                                        <h2>hi tolltip</h2> */}
                <FreedomFollowUpIcon
                  tooltiptitle={'Copy'}
                  padding="2px"
                  height="16px"
                  width="16px"
                  responsiveheight="16px"
                  responsivewidth="16px"
                  borderradius="5px"
                  bordercolor="none"
                  iconimg={copyIcon}
                  onClick={async () => {
                    await navigator.clipboard.writeText(`${systemID}`);
                  }}
                />
                {/* </Tooltip> */}
              </VoucherWrapper>
            </VoucherWrapper>
          </VoucherWrapper>
        </VoucherWrapper>
      </Grid>
    </Grid>
  );
  const CreditCardSummary = (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid container spacing={xl4 ? 3 : 1} item xs={12} sm={12} md={10} lg={8} xl={8}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
            <VoucherWrapper flexDirection={'column'} padding={'10px'} alignItems={'center'}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <FreedomTypographyText fontFamily="Poppins" fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Purchase Voucher{' '}
                </FreedomTypographyText>
                <ArrowRightAltIcon />
                <FreedomTypographyText fontFamily="Poppins" fontsize={'16px'} lineheight={'24px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Card Payment
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
            <VoucherWrapper>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400}>
                  {' '}
                  Deposit{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+form.voucherPrice)}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" lineheight={'18px'} fontsize={'12px'} fontweight={400}>
                  {' '}
                  Fee{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  ${FixedAfterTwoDigit1(+transactionFee)}
                </FreedomTypographyText>
              </VoucherWrapper>
            </VoucherWrapper>
            <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'} margin={0}>
              {paymentPayMethod === paymentAccountType.creditCardAccount ? (
                <FreedomCreditCard number={`**** **** **** ${cards?.last4}`} name={' '} expiry={formatExpirationDate(`${cards?.expiryMonth}/${cards?.expiryYear}`)} issuer={cards?.brand} cvc={form.cvc} />
              ) : (
                <PaymentCard paymentPayMethod={paymentPayMethod} username={username} />
              )}
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <VoucherWrapper height={'100%'} padding={'8px'} justifyContent={'space-between'}>
            <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Email{' '}
                </FreedomTypographyText>
                <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                  {email}
                </Title>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentDate()}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Card Number{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  **** **** **** {cards?.last4}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Payment Method{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {paymentPayMethod === paymentAccountType.internalAccount
                    ? 'Internal Account'
                    : paymentPayMethod === paymentAccountType.creditCardAccount
                    ? capitalizeFullName(`${cards?.funding} Card`)
                    : paymentPayMethod === paymentAccountType.bankAccount
                    ? 'Bank'
                    : ''}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Expiry Month{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {cards?.expMonth}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Expiry Year{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {cards?.expYear}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
            </VoucherWrapper>
            <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged =(Deposit + Fee)
                </FreedomTypographyText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+(form.voucherPrice + +transactionFee))}
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
  const InternalAccountSummary = (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="center">
      <Grid container spacing={xl4 ? 3 : 1} item xs={12} sm={12} md={10} lg={8} xl={8}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
            <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Internal Account{' '}
                </FreedomTypographyText>
              </Box>
              <Box>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'16px'} fontweight={700}>
                  {' '}
                  ${FixedAfterTwoDigit1(balance)}
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
            <VoucherWrapper justifyContent={'space-between'} padding={'10px'}>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400}>
                  {' '}
                  Deposit{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+form.voucherPrice)}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" lineheight={'18px'} fontsize={'12px'} fontweight={400}>
                  {' '}
                  Fee{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.placeholder} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  ${FixedAfterTwoDigit1(+transactionFee)}
                </FreedomTypographyText>
              </VoucherWrapper>
            </VoucherWrapper>
            <VoucherWrapper height={'200px'} bordercolor={'none'} padding={'0px'}>
              <PaymentCard paymentPayMethod={paymentPayMethod} username={username} />
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <VoucherWrapper height={'100%'} padding={'8px'} justifyContent="space-between">
            <VoucherWrapper height={'100%'} bordercolor={'none'} padding={'0px'}>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Email{' '}
                </FreedomTypographyText>
                <Title color={theme.palette.primary.paymentsummarycolor} fontsize={'12px'} fontweight={600} textalign={'right'}>
                  {email}
                </Title>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Transaction Date{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {currentDate()}
                </FreedomTypographyText>
              </VoucherWrapper>
              <Box sx={{ width: '100%' }}>
                <FreedomColorDivider dividerbackgroundcolor={theme.palette.border.containerborder} marginTop={'2px'} marginBottom={'2px'} />
              </Box>
              <VoucherWrapper bordercolor={'none'} flexDirection={'row'} justifyContent={'space-between'} padding={'10px'}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} fontweight={400}>
                  {' '}
                  Payment Method{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'12px'} fontweight={600}>
                  {' '}
                  {paymentPayMethod === paymentAccountType.internalAccount ? 'Internal Account' : paymentPayMethod === paymentAccountType.creditCardAccount ? 'Card Payment' : paymentPayMethod === paymentAccountType.bankAccount ? 'Bank' : ''}{' '}
                </FreedomTypographyText>
              </VoucherWrapper>
            </VoucherWrapper>
            <VoucherWrapper flexDirection={'row'} justifyContent={'space-between'} padding={'10px'} alignItems={'center'}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'12px'} lineheight={'18px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged{' '}
                </FreedomTypographyText>
                <FreedomTypographyText color={theme.palette.primary.paymentsummarycolor} fontFamily="Poppins" fontsize={'10px'} lineheight={'14px'} fontweight={400} textalign={'left'}>
                  {' '}
                  Total Amount Charged =(Deposit + Fee)
                </FreedomTypographyText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FreedomTypographyText fontFamily="Poppins" fontsize={'14px'} lineheight={'18px'} fontweight={700} color={theme.palette.primary}>
                  {' '}
                  $ {FixedAfterTwoDigit1(+(form.voucherPrice + +transactionFee))}
                </FreedomTypographyText>
              </Box>
            </VoucherWrapper>
          </VoucherWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
  /**
   * Fourth Step (3) => Transaction success screen
   */
  const successTransaction = (
    <Grid
      container
      // mt={5}
      // mb={5}
      // mb={10}
      height={'100%'}
      direction="column"
      alignItems="center"
      justifyContent={'center'}
    >
      <FreedomSuccessImage paymentmethod={paymentPayMethod} />
    </Grid>
  );

  /**
   * Add Credit card Or bank account information Stepper
   */
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <VoucherFirstStep voucherArr={voucherArr} ChangeVoucher={ChangeVoucher} selectedVoucher={selectedVoucher} />;
      case 1:
        return (
          <VoucherSecondStep
            paymentPayMethod={paymentPayMethod}
            balance={balance}
            handleChangeRadioButtonForPaymentMethod={handleChangeRadioButtonForPaymentMethod}
            selectedVoucher={selectedVoucher}
            form={form}
            desc="Voucher Product"
            duration={'1 Year'}
          />
        );
      case 2:
        if (paymentPayMethod == paymentAccountType.creditCardAccount) {
          return (
            <Grid container item sm={12} lg={12}>
              <PlanVoucherStepWrapper style={{ width: '100%' }}>
                <Grid container item justifyContent={'center'}>
                  <Grid item xs={12} md={10} m={xl4 ? 3 : 1}>
                    <FreedomAlert severity={'error'} message={openAlertMessage} opentAlert={opentAlert} setOpenAlert={setOpenAlert} />
                    <PaymentElement />
                  </Grid>
                </Grid>
              </PlanVoucherStepWrapper>
            </Grid>
          );
        } else if (paymentPayMethod == paymentAccountType.internalAccount) {
          return (
            <Grid item sm={12} lg={12}>
              <PlanVoucherStepWrapper>
                <Grid container justifyContent="center">
                  <FreedomOTP showMessage={showSnackbar} setActiveStep={setActiveStep} systemID={systemID} paymentOTPType={paymentOTPType.account} setLoading={setLoading} ref={confirmOTP} />
                </Grid>
              </PlanVoucherStepWrapper>
            </Grid>
          );
        } else if (paymentPayMethod == paymentAccountType.bankAccount) {
          return (
            <Grid item sm={12} lg={12}>
              <PlanVoucherStepWrapper>{BankDetial}</PlanVoucherStepWrapper>
            </Grid>
          );
        }
      case 3:
        if (paymentPayMethod == paymentAccountType.creditCardAccount) {
          return (
            <Grid item sm={12} lg={12}>
              <PlanVoucherStepWrapper>
                <FreedomAlert severity={'error'} message={openAlertMessage} opentAlert={opentAlert} setOpenAlert={setOpenAlert} />
                {CreditCardSummary}
              </PlanVoucherStepWrapper>
            </Grid>
          );
        } else if (paymentPayMethod == paymentAccountType.internalAccount) {
          return (
            <Grid item sm={12} lg={12}>
              <PlanVoucherStepWrapper>{InternalAccountSummary}</PlanVoucherStepWrapper>
            </Grid>
          );
        } else if (paymentPayMethod == paymentAccountType.bankAccount) {
          return (
            <Grid item sm={12} lg={12}>
              <PlanVoucherStepWrapper>{successTransaction}</PlanVoucherStepWrapper>
            </Grid>
          );
        }
      case 4:
        return (
          <Grid item sm={12} lg={12}>
            <PlanVoucherStepWrapper>{successTransaction}</PlanVoucherStepWrapper>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }

  const handleClick = () => {
    window.open(voucherVideoLinks(), '_blank');
  };

  try {
    return (
      <React.Fragment>
        {open && <FreedomSnackBar />}

        {balance < +form.voucherPrice + +transactionFee ? <InsufficientBalancePopup openDialog={openDialog} setOpenDialog={setOpenDialog} /> : null}

        {addAddressDialog && <AddressDialog openDialog={addAddressDialog} showMessage={showSnackbar} getAddress={address.fetchData} onClose={() => setAddAddressDialog(false)} />}

        {IsError && <ErrorDialogBox errormessage={errorMessage} setIsError={setIsError} iserror={IsError} />}

        <Box>
          <Grid container justifyContent="center" alignItems={'flex-start'} spacing={1}>
            <Grid item lg={1.5}>
              <Box>
                <PageTitle>{pageTitle}</PageTitle>
              </Box>
            </Grid>
            <StepperLable steps={steps} activeStep={activeStep} paymentPayMethod={paymentPayMethod} />
            <Grid item lg={1.5}>
              {activeStep !== steps.length - 1 && (
                <Box>
                  <YoutubeVideo onClick={handleClick} />
                </Box>
              )}
            </Grid>
          </Grid>
          {/* {activeStep !== steps.length - 1 && <Grid
              container
              item
              justifyContent={'flex-end'}
              sx={{
                [theme.breakpoints.up('lg')]: {
                  marginTop: -5
                },
                [theme.breakpoints.up('xl4')]: {
                  marginTop: -5*3
                },
              }}
            >
                 <YoutubeVideo text='Need Help?' onClick={handleClick}/>
            </Grid>} */}
          <Box sx={{ width: '100%' }} className={classes.removeBorder}>
            <Stepper activeStep={activeStep} orientation="vertical" className={classes.stapingCardBalance}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepContent
                    sx={{
                      margin: '0px!important',
                      padding: '0px!important'
                    }}
                    className={classes.removeBorder}
                  >
                    {getStepContent(index)}
                    <Box sx={{ mb: 2, [theme.breakpoints.up('xl4')]: {mb: 6} }}>
                      <Grid container spacing={2} justifyContent="center">
                        {hideBackButton === false && (
                          <Grid item xs={6} md={2}>
                            <FreedomButton2 borderradius={'10px'} onClick={handleBack} sx={{ mt: 2, mr: 1, [theme.breakpoints.up('xl4')]: {mt: 6, mr: 3} }} variant="outlined" btncolor={theme.palette.border.border}>
                              {`${t('Back')}`}
                            </FreedomButton2>
                          </Grid>
                        )}
                        <Grid item xs={6} md={2}>
                          <FreedomButton1
                            onClick={handleNext}
                            sx={{ mt: 2, mr: 1, [theme.breakpoints.up('xl4')]: {mt: 6, mr: 3} }}
                            borderradius={'10px'}
                            btnbackground={theme.palette.button.buttonbgcolor}
                            loading={+loading}
                            disabled={activeStep === 0 && selectedVoucher == '' ? true : false}
                            variant="contained"
                          >
                            {activeStep === steps.length - 1 ? `${t('Done')}` : `${t('Next')}`}
                          </FreedomButton1>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* )} */}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
          {/* </FreedomWrapper> */}
        </Box>
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default PlanVoucher;
