import { Typography } from '@mui/material';
import { maxWidth, style, styled } from '@mui/system';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

export const CustomTooltipp = styled('div')(({ theme }) => (props) => ({
  border: `2px solid ${theme.palette.border.border}`,
  boxShadow: `1px 1px 5px ${theme.palette.border.border}`,
  background: theme.palette.background.default,
  padding: props.padding || '5px',
  borderRadius: '5px',
  outline: 'none',
  [theme.breakpoints.up('xl4')]: {
    border: `6px solid ${theme.palette.border.border}`,
    boxShadow: `3px 3px 15px ${theme.palette.border.border}`,
    padding: getStringProduct(3, props?.padding) || '15px',
    borderRadius: '15px'
  }
}));

export const MaxWidth1440 = styled('div')(({ theme }) => (props) => ({
  maxWidth: '1440px',
  margin: '0 auto'
}));

export const StyledImage = styled('img')(({ theme }) => ({
  height: 32,
  width: 32,
  border: `2px solid ${theme.palette.border.border}`,
  borderRadius: '10px',
  background: theme.palette.background.default,
  [theme.breakpoints.up('xl4')]: {
    height: 80,
    width: 80,
    border: `5px solid ${theme.palette.border.border}`,
    borderRadius: '24px'
  }
}));

export const Legcolor = styled('div')(({ theme, height, width, backgroundcolor }) => ({
  height: height || '16px',
  width: width || '16px',
  background: backgroundcolor || theme.palette.primary.main,
  borderRadius: '1px',
  [theme.breakpoints.up('xl4')]: {
    width: '18px',
    height: '18px',
    borderRadius: '5px'
  }
}));

export const CardsWrapper = styled('div')(({ theme, height }) => ({
  background: theme.palette.background.default,
  border: `2px solid ${theme.palette.border.containerborder}`,
  height: height || '100%',
  borderRadius: '10px',
  padding: '8px',
  [theme.breakpoints.up('xlll')]: {
    padding: '16px'
  },
  [theme.breakpoints.up('xl4')]: {
    padding: '24px',
    borderRadius: '30px',
    border: `5px solid ${theme.palette.border.containerborder}`
  }
}));

export const StyledText = styled(Typography)(({ theme, fontweight }) => ({
  fontSize: '8px',
  fontWeight: fontweight || 400,
  color: '#ffffff',
  [theme.breakpoints.up('xl4')]: {
    fontSize: '24px'
  }
}));

export const LegcolorWrapper = styled('div')(({ theme, bordercolor, firstgraph, secondgraph, thirdgraph, forthgraph }) => ({
  background: theme.palette.background.default,
  padding: (firstgraph || secondgraph || thirdgraph || forthgraph) ? '3.12px' : '5.76px',
  border: `1px solid ${bordercolor || theme.palette.primary.main}`,
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('xlll')]: {
    borderRadius: '6px',
    padding: (firstgraph || secondgraph || thirdgraph || forthgraph) ? '4.5px' : '12px'
  },
  [theme.breakpoints.up('xl4')]: {
    // width: "317.51px",
    borderRadius: '12px',
    height: '42px',
    width: '42px',
    padding: firstgraph ? '5.5px' : secondgraph ? '5.5px' : thirdgraph ? '5.5px' : forthgraph ? '5.5px' : '18px',
    border: `2px solid ${bordercolor || theme.palette.primary.main}`
  }
}));

export const CountsWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.border.tabsborder}`,
  borderRadius: '5px',
  padding: '4px',
  [theme.breakpoints.up('xlll')]: {
    padding: '5px 6.5px 6.5px 5px'
  },
  [theme.breakpoints.up('xl4')]: {
    padding: '8px 9.5px 9.5px 8px',
    border: `5px solid ${theme.palette.border.tabsborder}`,
    borderRadius: '15px'
  }
}));

export const PageTitle = styled('h6')(({ theme }) => ({
  border: `2px solid ${theme.palette.border.containerborder}`,
  padding: '10px',
  borderRadius: '10px',
  textAlign: 'center',
  margin: '0px',
  height: '100%',
  [theme.breakpoints.up('xl4')]: {
    border: getDynamicBorder(3, `2px solid ${theme.palette.border.containerborder}`),
    padding: getStringProduct(3,'10px'),
    borderRadius: getStringProduct(3,'10px'),
    fontSize: getStringProduct(3, '16px')
  }
}));
