import React, { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import { FreedomScroll } from '../FreedomScroll/FreedomScroll';
import { ColorModeContext } from '../../../store';
import FreedomScrollBar from '../FreedomScrollBar/FreedomScrollBar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

// type GradientProps = {
//   gradientColors: string[];
// };
// const options = {
//   shouldForwardProp: (prop) => prop !== 'gradientColors'
// };

// const borderRadius = 15;
// const Boxx = styled(
//   Box,
//   options
// )(({ theme, gradientColors, padding }) => ({
//   position: 'relative',
//   //   border: "0px solid transparent",
//   backgroundClip: 'padding-box',
//   borderRadius,
//   padding: padding || '15px 16px',
//   // minWidth: '350px',
//   // minHeight: '400px',
//   // height: '100%',
//   // maxHeight: '400px',
//   // overflowY: 'scroll',
//   // overflowX: 'hidden',
//   // '&::-webkit-scrollbar': {
//   // 	display: 'none'
//   // },

//   '&:after': {
//     position: 'absolute',
//     top: -2,
//     left: -2,
//     right: -2,
//     bottom: 2,
//     background: `linear-gradient(to bottom, ${gradientColors.join(',')})`,
//     content: '""',
//     zIndex: -1,
//     borderRadius
//   }
// }));

const AffiliateCardBackground = styled('div')(({ theme, height, simple, marginTop, border, bg, padding, marginBottom }) => ({
  background: bg ? theme.palette.background.default : '',
  borderRadius: '10px',
  padding: padding || '10px 16px',
  marginTop: marginTop || '10px',
  border: `2px solid ${theme.palette.border.containerborder}`,
  // cursor: simple ? "" : "pointer",
  width: '100%',
  height: height || '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: marginBottom || '0px',
  // "&:hover": {
  //   // boxShadow: '0px 0px 5px 1px #FFEFB5',
  //   background: simple ? "" : theme.palette.hoverColor.cardHover,
  // },
  [theme.breakpoints.down('md')]: {
    padding: '10.27px 5.29px',
    borderRadius: '5px'
  },
  [theme.breakpoints.up('xl4')]: {
    borderRadius: getStringProduct(3, '10px'),
    padding: getStringProduct(3, padding || '10px 16px'),
    marginTop: getStringProduct(3, marginTop || '10px'),
    border: getDynamicBorder(3, `2px solid ${theme.palette.border.containerborder}`),
    height: getStringProduct(3, height),
    marginBottom: getStringProduct(3, marginBottom || '0px')
  }
}));

const FreedomWrapperRoot = ({ children, table = 'false', marginTop = '50px', height = '48', padding, perfectScroll = false, border = true, bg = true, marginBottom = '0px', width = '100%', ...props }) => {
  const theme = useTheme();
  const {
    colorMode: { toggleMode, mode }
  } = useContext(ColorModeContext);
  try {
    return (
      <AffiliateCardBackground padding={padding} marginTop={marginTop} border={border} bg={bg} {...props}>
        {table === 'true' ? children : perfectScroll === false ? <FreedomScroll height={height}>{children}</FreedomScroll> : <PerfectScrollbar style={{ height: `${height}vh`, width: width }}>{children}</PerfectScrollbar>}
      </AffiliateCardBackground>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

// const FreedomWrapperRoot = ({
//   children,
//   table = 'false',
//   marginTop = '80px',
//   height = '48',
//   padding,
//   perfectScroll = false,
// }) => {
//   const theme = useTheme();
//   const {
//     colorMode: { toggleMode, mode },
//   } = useContext(ColorModeContext);
//   return (
//     <Boxx
//       gradientColors={
//         mode === 'light'
//           ? [
//               theme.palette.background.wrapperborderTop,
//               theme.palette.background.wrappereBottom,
//             ]
//           : [
//               theme.palette.primary.darkBorderColorWrapper,
//               theme.palette.primary.black,
//             ]
//       }
//       padding={padding}
//       style={{
//         background:
//           mode === 'light'
//             ? `linear-gradient(180deg, ${theme.palette.background.wrappereBottom} 0%, ${theme.palette.background.wrapperbgTop} 100%)`
//             : `linear-gradient(180deg, #141414 0%, #000000 100%)`,
//         marginTop: marginTop,
//       }}
//     >
//       {table === 'true' ? (
//         children
//       ) : perfectScroll === false ? (
//         <FreedomScroll height={height}>{children}</FreedomScroll>
//       ) : (
//         <PerfectScrollbar
//           style={{ height: `${height}vh`, width: `100%` }}
//         >
//           {children}
//         </PerfectScrollbar>
//       )}
//     </Boxx>
//   );
// };

export default FreedomWrapperRoot;

// export const FreedomWrapperSolidBg = styled(Box)(({ theme, bgColor }) => ({
// 	position: 'relative',
// 	//   border: "0px solid transparent",
// 	backgroundClip: 'padding-box',
// 	borderRadius,
// 	padding: '15px 16px',

// 	'&:after': {
// 		position: 'absolute',
// 		top: -2,
// 		left: -2,
// 		right: -2,
// 		bottom: 2,
// 		background: `linear-gradient(to bottom, ${gradientColors.join(',')})`,
// 		content: '""',
// 		zIndex: -1,
// 		borderRadius
// 	}
// }));
