import React from 'react';
import { makeStyles } from '@mui/styles';
import { Radio, useTheme } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

const useStyles = makeStyles((theme) => ({
  // root: {
  //     padding: "10px",
  // }
}));

export const FreedomRadioButton1 = (props) => {
  const { checked, height, width, radioiconsize, margin } = props;
  const classes = useStyles();
  const theme = useTheme();
  // debugger
  try {
    return (
      <Radio
        {...props}
        // size="small"
        sx={{
          background: 'transparent',
          margin: margin || '0px',
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: '7px',
          border: `2px solid ${theme.palette.border.border}`,
          color: theme.palette.border.border,
          '& .MuiSvgIcon-root': {
            fontSize: radioiconsize || 18,
            [theme.breakpoints.up('xl4')]: {
              fontSize: getStringProduct(3, radioiconsize) || 54,
              // border: getStringProduct(3, `2px solid ${theme.palette.border.border}`),
              // margin: getStringProduct(3, margin || '0px'),
              // height: `${height * 3}px`,
              // width: `${width * 3}px`,
              // borderRadius: getStringProduct(3, '7px'),
            }
          },
          cursor: 'pointer',
          // "&:hover": {
          //   background: theme.palette.primary.white,
          // },
          // "&.Mui-checked:hover": {
          //   background: theme.palette.primary.white,
          // },
          '&.Mui-checked': {
            color: theme.palette.border.border,
            background: theme.palette.background.default
          },
          [theme.breakpoints.up('xl4')]: {
            margin: getStringProduct(3, margin || '8px 20px 8px 10px'),
            borderRadius: getStringProduct(3, '7px'),
            padding: '27px',
            // fontSize: `${getStringProduct(3, '12px')} !important`,
            border: getDynamicBorder(3, `2px solid ${theme.palette.border.border}`),
          }
        }}
        // sx={{
        //   "&, &.Mui-checked": {
        //     color: radiocolor,
        //   },
        // }}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
