import styled from '@emotion/styled';
import { getStringProduct } from '../../../../utils/utilityFunctions';

export const Wrapper = styled('div')(({ theme, bgcolor, padding, height, margin, flexdirection, borderRadius, bordercolor, justifycontent, alignItems, width }) => ({
  border: bordercolor ? `2px solid ${bordercolor}` : `2px solid ${theme.palette.border.containerborder}`,
  margin: margin || '4px',
  padding: padding || '5px',
  borderRadius: borderRadius || '10px',
  background: bgcolor || '',
  height: height || '',
  width: width || '100%',
  display: 'flex',
  flexDirection: flexdirection || 'column',
  justifyContent: justifycontent,
  alignItems: alignItems || 'center',
  [theme.breakpoints.up('xl4')]:{
    border: bordercolor ? getStringProduct(3, `2px solid ${bordercolor}`) : getStringProduct(3, `2px solid ${theme.palette.border.containerborder}`),
    margin: getStringProduct(3, margin || '4px'),
    padding: getStringProduct(3, padding || '5px'),
    borderRadius: getStringProduct(3, borderRadius || '10px'),
    height: getStringProduct(3, height || ''),
    width: getStringProduct(3, width) || '100%',
  }
}));

export const Title = styled('div')(({ theme, fontweight, fontsize, lineheight, textalign, color }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || '400',
  fontSize: fontsize || '13px',
  lineHeight: lineheight || '18px',
  color: color || theme.palette.primary,
  whiteSpace: 'nowrap',
  width: '70%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: textalign || 'center',
  [theme.breakpoints.up('xl4')]:{
    fontWeight: getStringProduct(3, fontweight || '400px'),
    fontSize: getStringProduct(3, fontsize || '13px'),
    lineHeight: getStringProduct(3, lineheight || '18px'),
  }
}));
