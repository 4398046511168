import { Box, Grid, useTheme } from '@mui/material';
import React from 'react';
import { Para } from '../WelcomeScreenElement/WelcomeScreenElement';
import { styled } from '@mui/styles';
import { appstore, googleplaystore } from '../../../url/SvgLinks';

export const Image = styled('img')(({ theme }) => ({
  height: '74px',
  width: '250px',
  cursor: 'pointer'
}));

const TraderPuginContent = ({ margintop = '200px' }) => {
  const theme = useTheme();
  const handleGooglePlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.mf.R27F.myfreedom', '_blank');
  };
  const handleAppStore = () => {
    window.open('https://apps.apple.com/us/app/myfreedom-ltd/id6446827414', '_blank');
  };
  return (
    <Box mt={margintop}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} md={12}>
          <Para smfontsize="20px" textalignsm="center" fontsize="55px" fontweight={500} lineheight="82.5px">
            Stay ahead of the market
          </Para>
        </Grid>
        <Grid item xs={12} md={6}>
          <Para smfontsize="16px" textalignsm="center" fontsize="35px" lineheight="52.5px">
            Download the latest mobile app versions to access the new trading plugin on the go!
          </Para>
        </Grid>
      </Grid>
      <Grid container columnSpacing={12} mt={5} justifyContent="center" alignItems="center" sx={{ [theme.breakpoints.down('sm')]: { gap: 2 } }}>
        <Grid item>
          <Image src={googleplaystore} alt={'playsore image'} onClick={handleGooglePlayStore} />
        </Grid>
        <Grid item>
          <Image src={appstore} alt={'playsore image'} onClick={handleAppStore} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TraderPuginContent;
