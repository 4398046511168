import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const LayoutWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '90vw',
  margin: '80px auto 60px auto',
  // marginBottom: '60px',
  height: '100%',
  // height: 'calc(100vh - 170px)',
  [theme.breakpoints.down('xs')]: {
    margin: '40px auto auto auto'
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100vw'
  },
  [theme.breakpoints.up('xlll')]: {
    margin: '140px auto 60px auto'
  },
  [theme.breakpoints.up('xl4')]: {
    margin: '220px auto 180px auto'
  }
}));

const Layout = ({ children }) => {
  return <LayoutWrapper component="main">{children}</LayoutWrapper>;
};

export default Layout;
