import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTitle = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    // Get the last segment of the pathname
    const segments = location.pathname.split('/').filter(Boolean);
    const lastSegment = segments[segments.length - 1];

    // Format the last segment into a title
    const title = lastSegment
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word

    // Update the document title
    document.title = `Freedom - ${title}` || ''; // Fallback to a default title
    setPageTitle(title)
  }, [location]);

  return {pageTitle}
};

export default usePageTitle;
