import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, useTheme } from '@mui/material';
import { ColorModeContext } from '../../../store';
import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& input[type=number]': {
//       '-moz-appearance': 'textfield'
//     },
//     '& input[type=number]::-webkit-outer-spin-button': {
//       '-webkit-appearance': 'none',
//       margin: 0
//     },
//     '& input[type=number]::-webkit-inner-spin-button': {
//       '-webkit-appearance': 'none',
//       margin: 0
//     },
//     '& .MuiInputBase-root': {
//       height: 150
//     },
//     '& .MuiFilledInput-root': {
//       overflow: 'hidden',
//       width: '100%',
//       borderRadius: '8px',
//       backgroundColor: theme.palette.background.default,
//       color: `${theme.palette.text.primary} !important`,
//       border: (props) => (props.errorBorder ? `2px solid ${theme.palette.primary.error}` : `2px solid ${theme.palette.border.textFieldBorder}`),
//       transition: theme.transitions.create(['border-color', 'box-shadow']),
//       '&:hover': {
//         background: theme.palette.hoverColor.textFieldHover
//       },
//       '& .MuiFilledInput-root.Mui-focused': {
//         background: 'yellow'
//       },
//       '& .MuiFilledInput-input': {
//         textTransform: (props) => (props?.texttransform ? props?.texttransform : 'capitalize')
//       },
//       '& label.Mui-focused': {
//         color: '#r'
//       },
//       '& :-webkit-autofill': {
//         '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
//       },
//       input: {
//         '&::placeholder': {
//           textOverflow: 'ellipsis !important',
//           color: 'red'
//         }
//       }
//     },
//     '& .MuiFilledInput-root.Mui-focused': {
//       outline: `0px 0px 2px 1px ${theme.palette.border.textFieldBorder}`
//     },

//     [theme.breakpoints.up('xl4')]: {
//     '& .MuiFilledInput-root': {
//       borderRadius: getStringProduct(3, '8px'),
//       border: (props) => (props.errorBorder ? getDynamicBorder(3, `2px solid ${theme.palette.primary.error}`) : getDynamicBorder(3, `2px solid ${theme.palette.border.textFieldBorder}`)),
//     },
//     '& .MuiFilledInput-root.Mui-focused': {
//       outline: `0px 0px 2px 1px ${theme.palette.border.textFieldBorder}`
//     },
//     }
//   },

//   InputTextField: {
//     '& .MuiFilledInput-root': {
//       fontFamily: 'Poppins',
//       height: '63px',
//       paddingLeft: 10,
//       overflow: 'hidden',
//       borderRadius: '8px',
//       backgroundColor: theme.palette.primary.main,
//       color: `${theme.palette.primary.lightwhite} !important`,
//       transition: theme.transitions.create(['border-color', 'box-shadow']),
//       '&:hover': {
//         backgroundColor: `${theme.palette.primary.dialogtextfield} !important`,
//         color: `${theme.palette.primary.lightwhite} !important`
//       },
//       '&:focus': {
//         backgroundColor: `${theme.palette.primary.dialogtextfield} !important`,
//         color: `${theme.palette.primary.lightwhite} !important`,
//         borderColor: theme.palette.primary.main
//       },
//       '& label.Mui-focused': {
//         color: theme.palette.primary.lightwhite
//       },
//       '& input.form-control': {
//         color: `${theme.palette.primary.lightwhite} !important`
//       },
//       '& :-webkit-autofill': {
//         // '-webkit-text-fill-color': 'white',
//         '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
//       }
//     },
//     [theme.breakpoints.up('xl4')]: {
//       '& .MuiFilledInput-root': {
//       height: getStringProduct(3, '63px'),
//       borderRadius: getStringProduct(3, '8px'),
//       paddingLeft: 30,
//       borderRadius: getStringProduct(3, '8px'),
//     }
//     }
//   },
//   InputTextFieldDark: {
//     '& .MuiFilledInput-root': {
//       height: '63px',
//       overflow: 'hidden',
//       borderRadius: '8px',
//       backgroundColor: theme.palette.primary.white,
//       color: `${theme.palette.primary.black} !important`,
//       border: '1px solid orange',
//       transition: theme.transitions.create(['border-color', 'box-shadow']),
//       '&:hover': {
//         color: `${theme.palette.primary.lightwhite} !important`
//       },
//       '& .MuiFilledInput-root.Mui-focused': {
//         background: 'yellow'
//       },

//       '&:focus': {
//         background: 'green',
//         color: 'red !important',
//         outline: `1px 1px 10px ${theme.palette.primary.main}`
//       },
//       '& label.Mui-focused': {
//         color: theme.palette.primary.lightblack
//       },
//       '& input.form-control': {
//         color: theme.palette.primary.lightblack
//       },
//       '& :-webkit-autofill': {
//         '-webkit-box-shadow': '0 0 0px 1000px #00000000 inset'
//       },
//       [theme.breakpoints.up('xl4')]: {
//         height: getStringProduct(3, '63px'),
//       borderRadius: getStringProduct(3, '8px'),
//       }
//     },
//     [theme.breakpoints.up('xl4')]: {
//       '& .MuiFilledInput-root': {
//       height: getStringProduct(3, '63px'),
//       borderRadius: getStringProduct(3, '8px'),
//     }
//     }
//   }
// }));

export const FreedomTextField = (props) => {
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  const theme = useTheme();
  try {
    return (
      <TextField
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '8px',
            backgroundColor: theme.palette.background.default,
            color: `${theme.palette.text.primary} !important`,
            border: (props) => (props.errorBorder ? `2px solid ${theme.palette.primary.error}` : `2px solid ${theme.palette.border.textFieldBorder}`),
            fontFamily: 'Poppins',
            overflow: 'hidden',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:hover': {
              background: theme.palette.hoverColor.textFieldHover
            },
           
            [theme.breakpoints.up('xl4')]: {
              height: 168,
              fontSize: 48,
              borderRadius: '24px',
              border: (props) => (props.errorBorder ? `6px solid ${theme.palette.primary.error}` : `6px solid ${theme.palette.border.textFieldBorder}`),
              lineHeight: '22.88px',
              '&:valid': {
                background: 'yellow'
              }
            }
            // height: 200
          },
          '& .MuiInputBase-input': {
              padding: '25px 12px 8px 12px',
              [theme.breakpoints.up('xl4')]: {
                padding: '75px 36px 24px 36px',
              }
          },
          '& .MuiFormLabel-root': {
            color: theme.palette.primary.placeholder,
            [theme.breakpoints.up('xl4')]: {
            fontSize: 48,
            lineHeight: '68.64px',
            transform: props.value ? 'translate(36px, 16px) scale(.75)' : 'translate(36px, 48px) scale(1)',
            marginBottom: '24px',
          }},
          '& .MuiFormLabel-root.Mui-focused': {
            [theme.breakpoints.up('xl4')]: {
              transform: 'translate(36px, 16px) scale(.75)',
          }},

          '& .MuiFormHelperText-root': {
            color: `${theme.palette.primary.error} !important`,
            [theme.breakpoints.up('xl4')]: {
              fontSize: 36,
            }
          }
        }}
        // className={`${classes.root}`}
        {...props}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};
