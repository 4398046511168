import { useMsal } from '@azure/msal-react';
import React from 'react';
import { msalConfig } from '../auth/authConfig';
import { msalConfigSignIn } from '../auth/signInAuthConfig';
import { appInsights } from '../auth/appInsight';

const useLogout = () => {
  const { instance } = useMsal();
  const handleLogOutRedirect = async () => {
    let browserName = window.navigator.userAgent;
    if (browserName.indexOf('Chrome') > -1 || browserName.indexOf('Edge') > -1 || browserName.indexOf('Opera') > -1 || browserName.indexOf('OPR') > -1) {
      sessionStorage.clear();
      localStorage.clear();
      await instance.logoutRedirect({
        postLogoutRedirectUri: msalConfigSignIn.auth.postLogoutRedirectUri
      });
    }
    if (browserName.indexOf('Firefox') > -1 || browserName.indexOf('Safari') > -1) {
      await instance.logoutRedirect({
        postLogoutRedirectUri: msalConfigSignIn.auth.postLogoutRedirectUri
      });
    }
  };
  try {
    return { handleLogOutRedirect };
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default useLogout;
