import React from 'react';
import FreedomPanel from '../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import { Box, Grid, useTheme } from '@mui/material';
import SummaryComp from '../shared/SummaryComp';
import { PlanVoucherStepWrapper } from '../PlanVoucher1';
import { getStringProduct } from '../../utils/utilityFunctions';

const VoucherSecondStep = ({ paymentPayMethod, handleChangeRadioButtonForPaymentMethod, balance, selectedVoucher, form, desc, duration }) => {
  const theme = useTheme()
  return (
    <Grid item sm={12} lg={12}>
      <PlanVoucherStepWrapper>
        <Box sx={{ maxWidth: '1440px', margin: 'auto', [theme.breakpoints.up('xl4')]: {maxWidth: getStringProduct(3, '1440px')} }}>
          <SummaryComp paymentPayMethod={paymentPayMethod} balance={balance} handleChangeRadioButtonForPaymentMethod={handleChangeRadioButtonForPaymentMethod} selectedVoucher={selectedVoucher} form={form} desc={desc} duration={duration} />
        </Box>
      </PlanVoucherStepWrapper>
    </Grid>
  );
};

export default VoucherSecondStep;
