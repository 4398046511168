import { Box, Grid, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import FreedomTypographyText from '../SharedComponent/FreedomTypographyText/FreedomTypographyText'
import { images } from '../../url/SvgLinks';
import { fontSize } from '@mui/system';
import { getDynamicBorder, getStringProduct } from '../../utils/utilityFunctions';

const Wrapper = styled('div')(({ theme, height }) => ({
  cursor: 'pointer', 
  display: 'flex', 
  alignItems: 'center', 
  borderRadius: '8px',
  justifyContent:'center', 
  border: `2px solid ${theme.palette.border.containerborder}`, 
  padding:"7px 10px",
  height: height || '100%',
  [theme.breakpoints.up('xl4')]:{
    height: getStringProduct(3, height) || '100%',
    border: getDynamicBorder(3, `2px solid ${theme.palette.border.containerborder}`),
    borderRadius: '15px',
    padding: getStringProduct(3, "7px 10px"),
    borderRadius: '24px',
  }
}));

const ImagIcon = styled('img')(({ theme }) => ({
  width: '32px',
  height: '24px',
  marginRight: '8px',
  [theme.breakpoints.up('xl4')]:{
    width: '96px',
    height: '72px',
    marginRight: '24px',
  }
}));

const StyledText = styled(Typography)(({ theme }) => ({

  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '12px',

  [theme.breakpoints.up('xl4')]:{
    fontSize: '36px'
  }

}));

const youtubeIcon = images.youtubeIcon;

const YoutubeVideo = ({text="Watch Intro", onClick, height}) => {
    const theme = useTheme()
  return (
    <Wrapper height={height} onClick={() => {if(onClick) onClick()}}>
        <ImagIcon src={youtubeIcon} alt="img" />
      <Grid item>
        <StyledText>{text}</StyledText>
      </Grid>
    </Wrapper>
  )
}

export default YoutubeVideo