import { Alert, Collapse, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cookieAlert: {
    [theme.breakpoints.up('xl4')]: {
      padding: '30px',
      "& .MuiAlert-icon": {
        fontSize: 72
      }
    }
  }
}));

const FreedomAlert = ({ severity, message, opentAlert, setOpenAlert }) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Collapse in={opentAlert}>
      <Alert
        severity={severity}
        className={classes.cookieAlert}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenAlert(false);
            }}
          >
            <CloseIcon fontsize="inherit" sx={{[theme.breakpoints.up('xl4')]: {fontSize: 72}}}/>
          </IconButton>
        }
        sx={{ mb: 2, [theme.breakpoints.up('xl4')]: {mb: 6, fontSize: '48px'} }}
      >
        {message}
      </Alert>
    </Collapse>
  );
};

export default FreedomAlert;
