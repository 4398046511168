import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { appInsights } from '../../../auth/appInsight';
import { useMediaQuery, useTheme } from '@mui/material';
import { getStringProduct } from '../../../utils/utilityFunctions';

const FreedomScrollBar = ({ vh, height = '100%', width = '100%', children, containerRef }) => {
  const theme = useTheme()
  const xl4 = useMediaQuery(theme.breakpoints.up('xl4'))
  try {
    return (
      <PerfectScrollbar
        containerRef={containerRef}
        style={{
          height: vh ? vh : xl4 ? getStringProduct(3, height)  : `${height}px`,
          width: `${width}`
          // paddingRight: '10px'
        }}
      >
        {children}
      </PerfectScrollbar>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomScrollBar;
