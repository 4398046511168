import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CloseIcon = ({ handleonclick, padding }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      sx={{
        // padding: padding || '0px 16px 0px 0px',
        position: 'absolute',
        right: isSmallScreen ? '5px' : '10px',
        top: isSmallScreen ? '5px' : '10px',
        [theme.breakpoints.up('xl4')]: {
          right: '30px',
          top: '30px',
        }
      }}
      justifyContent={'end'}
    >
      <CloseRoundedIcon
        sx={{
          color: theme.palette.border.border,
          border: `2px solid ${theme.palette.border.border}`,
          backgroundColor: theme.palette.background.default,
          borderRadius: '7px',
          cursor: 'pointer',
          [theme.breakpoints.up('xl4')]: {
            border: `6px solid ${theme.palette.border.border}`,
            borderRadius: '21px',
            fontSize: '72px'
          }
        }}
        onClick={handleonclick}
      />
    </Grid>
  );
};

export default CloseIcon;
