import React from 'react';
import { Box, useTheme } from '@mui/material';

import './FreedomLinearLoader.css';
import { appInsights } from '../../auth/appInsight';
import { getStringProduct } from '../../utils/utilityFunctions';

const FreedomLinearLoader = ({ marginleft, margintop }) => {
  const theme = useTheme();
  try {
    return (
      <Box
        component="span"
        className="loader"
        sx={{
          color: theme.palette.primary.onBoardTextColor,
          marginLeft: marginleft || '',
          marginTop: margintop || '',
          [theme.breakpoints.up('xl4')]: {
            marginLeft: getStringProduct(3, marginleft) || '',
            marginTop: getStringProduct(3, margintop) || '',
          }
        }}
      ></Box>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomLinearLoader;
