import React from 'react';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { capitalize, getStringProduct } from '../../../utils/utilityFunctions';
import { appInsights } from '../../../auth/appInsight';

export const Title = styled('div')(({ theme, fontweight }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || '500',
  fontSize: '32px',
  lineHeight: '48px',
  color: theme.palette.primary.onBoardTextColor,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '36px'
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, '32px'),
    lineHeight: getStringProduct(3, '48px'),
  }
}));
export const Para = styled('p')(({ theme, fontsize, smfontsize, smlineHeight, xlfontsize, lineheight, xllineHeight, textalign, fontweight, color, padding360, marginbottom, textalignsm }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || 400,
  fontSize: fontsize || '18px',
  lineHeight: lineheight || '27px',
  textAlign: textalign || 'center',
  marginBottom: marginbottom,
  color: color || theme.palette.primary.onBoardTextColor,
  opacity: '0.9',
  [theme.breakpoints.down('sm')]: {
    fontSize: smfontsize || '12px',
    lineHeight: smlineHeight || '18px',
    padding: padding360 || '20px',
    textAlign: textalignsm || 'justify',
  },
  [theme.breakpoints.up('xll')]: {
    fontSize: xlfontsize || '14px',
    lineHeight: xllineHeight || '21px'
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, fontsize) || '54px',
    lineHeight: getStringProduct(3, lineheight) || '84px',
    marginBottom: getStringProduct(3, marginbottom) || '48px',
  },

}));

export const UserName = () => {
  const userName = useSelector((state) => state.user.fullName);
  try {
    return <Title>Hello {capitalize(userName)}!</Title>;
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export const FreedomViewAllText = styled('h4')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  cursor: 'pointer',
  color: theme.palette.primary,
  [theme.breakpoints.up('xlll')]: {
    fontSize: '16px',
    lineHeight: '24px'
  },
  [theme.breakpoints.up('xl4')]: {
    fontSize: getStringProduct(3, '12px'),
    lineHeight: getStringProduct(3,'18px'),
  },

}));
