import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import { appInsights } from '../../../auth/appInsight';
import PropTypes from 'prop-types';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

const FreedomButton = styled(Button)(
  ({
    theme,
    btncolor,
    btnborder,
    width,
    padding,
    lineheight,
    borderradius,
    btnbackground,
    gap,
    fontsize,
    xlfontsize,
    xllineheight,
    height,
    xlheight,
    fontweight,
    btnboxshadow,
    btnbackground1,
    btnboxshadow1,
    btnhovercolor,
    btndisabledcolor,
    btndisabledborder,
    btndisabledbg,
    texthovercolor,
    disabled,
    colorbtn,
  }) => ({
    background: disabled ? 'transparent' : btnbackground || 'transparent',
    boxShadow: `0px 4px 14px ${btnboxshadow}`,
    border: btnborder ? `2px solid ${btnborder}` : `2px solid ${btncolor}`,
    borderRadius: borderradius || '10px',
    gap: gap,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: fontweight || '500',
    fontSize: fontsize || '16px',
    lineHeight: lineheight || '15px',
    color: colorbtn || btncolor || theme.palette.primary.white,
    textTransform: 'capitalize',
    width: width || '100%',
    height: height || '56px',
    padding: padding || '2px 6px',
    '&:hover': {
      background: btnhovercolor || theme.palette.button.buttonbgcolor
    },
    '&:focus': {
      border: `2px solid ${theme.palette.text.primary}`
    },
    '&:disabled': {
      // background: btndisabledbg || theme.palette.button.disablebtncolor,
      color: btndisabledcolor,
      border: btndisabledborder ? `2px solid ${btndisabledborder}` : `2px solid ${btndisabledcolor}`
    },
    // "&:focus": {
    //   // background: btndisabledbg || theme.palette.button.disablebtncolor,
    //   // color: theme.pal,
    //   border: `2px solid ${theme.palette.text.primary}`,
    // },
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
      fontSize: '12px'
    },
    [theme.breakpoints.up('xll')]: {
      // padding: "4px 6px",
      height: xlheight || '56px',
      fontSize: xlfontsize || '14px',
      lineHeight: xllineheight || '24px'
    },
    [theme.breakpoints.up('xl4')]: {
      // padding: "4px 6px",
      fontSize: getStringProduct(3, fontsize) || '42px',
      lineHeight: getStringProduct(3, lineheight) || '72px',
      boxShadow: `0px 12px 42px ${btnboxshadow}`,
      border: btnborder ? `6px solid ${btnborder}` : `6px solid ${btncolor}`,
      borderRadius: getStringProduct(3, borderradius) || '30px', 
      gap: getStringProduct(3, gap),
      width: getStringProduct(3, width) || '100%',
      height: getStringProduct(3, height) || '162px',
      padding: getStringProduct(3, padding) || '6px 18px',
      },
  })
);

const FreedomButton1 = (props) => {
  const { children, disabled = false, loading = false, variant = 'contained', sizecircle, ...rest } = props;
  const theme = useTheme();
  try {
    return (
      <React.Fragment>
        {loading ? (
          <FreedomButton sx={{ mr: 1, [theme.breakpoints.up('xl4')]: {mr: 3} }} disableElevation={true} disableRipple={true} disableFocusRipple={true} variant={variant} {...rest} disabled={true}>
            <CircularProgress size={sizecircle} sx={{ color: theme.palette.text.primary }} />
          </FreedomButton>
        ) : (
          <FreedomButton variant={variant} disableElevation={true} disableRipple={true} disableFocusRipple={true} disabled={disabled} {...rest}>
            {children}
          </FreedomButton>
        )}
      </React.Fragment>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomButton1;

PropTypes.resetWarningCache();

FreedomButton1.propType = {
  loading: PropTypes.bool
};
