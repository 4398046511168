import { TextField, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

const SearchFiled = styled(TextField)(({ theme, height }) => ({
  border: `2px solid ${theme.palette.border.containerborder}`,
  fontFamily: 'Poppins',
  justifyContent: 'center',
  width: '100%',
  height: height || '',
  padding: '0px',
  borderRadius: '8.0859px',
  background: theme.palette.background.default,
  '&:focused': {
    boxShadow: `1px 1px 1px ${theme.palette.primary.lightblack}`
  },
  '& .MuiOutlinedInput-root': {
    '& > fieldset': {
      border: 'none'
    }
  },
  [theme.breakpoints.up('xlll')]: {
    borderRadius: '5px'
  },
  [theme.breakpoints.up('xl4')]: {
    borderRadius: getStringProduct(3, '8.0859px'),
    border: getDynamicBorder(3, `2px solid ${theme.palette.border.containerborder}`),
    height: getStringProduct(3, height) || '',
    padding: getStringProduct(3, '5px 14px')
  },
}));

const FreedomSearchField = (props) => {
  const theme = useTheme();
  const xllScreen = useMediaQuery(theme.breakpoints.up('xlll'));
  const xl4Screen = useMediaQuery(theme.breakpoints.up('xl4'));
  const { placeholder = 'Search' } = props;
  try {
    return (
      <SearchFiled
        fullWidth
        inputProps={{
          style: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '400',
            padding: xl4Screen ? getStringProduct(3, '5px 14px') : xllScreen ? '6px 18px' : '5px 14px',
            fontSize: xl4Screen ? getStringProduct(3, '15px') : xllScreen ? '18px' : '15px',
            lineHeight: xl4Screen ? getStringProduct(3, '21px') : xllScreen ? '24x' : '21px'
          }
        }}
        placeholder={placeholder}
        {...props}
      />
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomSearchField;
