import { useContext } from 'react';
import { ColorModeContext } from '../../../store';
// import useStyle from '../../Style';
import { Box, styled } from '@mui/material';
import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

const Wrapper = styled(Box)(({ theme, padding, border, bgcolor }) => ({
  background: bgcolor || theme.palette.background.default,
  border: border || `2px solid ${theme.palette.border.containerborder}`,
  width: '100%',
  // height: '100%',
  padding: padding || '22.99px 43.24px 36.33px 31.19px',
  borderRadius: '10px',
  '::-webkit-scrollbar': {
    width: 0 /* Remove scrollbar space */,
    background: 'transparent' /* Optional: just make scrollbar invisible */
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px'
  },
  [theme.breakpoints.up('xl4')]: {
    border: getDynamicBorder(3, border) || getDynamicBorder(3,`2px solid ${theme.palette.border.containerborder}`),
    padding: getStringProduct(3, padding || '22.99px 43.24px 36.33px 31.19px'),
    borderRadius: getStringProduct(3,'10px'),
  },
  // [theme.breakpoints.up("xlll")]: {
  //   height: "calc(100vh - 250px)",
  // },
}));

// const useStyle = makeStyles((theme) => ({
//   boxWrapLight: {
//     background: theme.palette.background.default,
//     border: `2px solid ${theme.palette.border.containerborder}`,
//     width: "100%",
//     padding: (props) => props.padding || "22.99px 43.24px 36.33px 31.19px",
//     borderRadius: "10px",
//     [theme.breakpoints.down("sm")]: {
//       padding: "8px",
//     },
//   },
// }));

const FreedomPanel = (props) => {
  const { children, ...rest } = props;
  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  // const classes = useStyle(props);

  try {
    return (
      // <Box  className={mode === "light" ? (classes.boxWrapLight) : (classes.boxWrapDark)}>
      <Wrapper {...rest}>{children}</Wrapper>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomPanel;
