import React from 'react';

import { styled } from '@mui/system';
import { appInsights } from '../../../auth/appInsight';
import { getStringProduct } from '../../../utils/utilityFunctions';

const Divider = styled('div')((props) => {
  const {theme} = props
  return ({
  marginTop: props.marginTop || '20px ',
  marginBottom: props.marginBottom || '20px',
  height: '0.5px',
  backgroundColor: props.dividerbackgroundcolor,
  [theme.breakpoints.up('xl4')]: {
    height: '1.5px',
    marginTop: getStringProduct(3, props?.marginTop) || '60px ',
    marginBottom: getStringProduct(3, props?.marginBottom) || '60px',
  }
})});
const FreedomColorDivider = (props) => {
  const { dividerbackgroundcolor } = props;
  // const classes = useStyle();
  try {
    return <Divider {...props} dividerbackgroundcolor={dividerbackgroundcolor} />;
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomColorDivider;
