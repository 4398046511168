import React from 'react';
import FreedomPanel from '../../Components/SharedComponent/FreedomPanel/FreedomPanel';
import { Grid, useMediaQuery } from '@mui/material';
import FreedomVoucherCard from '../../Components/SharedComponent/FreedomVoucherCard/FreedomVoucherCard';
import { PlanVoucherStepWrapper } from '../PlanVoucher1';

const VoucherFirstStep = ({ voucherArr, ChangeVoucher, selectedVoucher }) => {
  const xl4 = useMediaQuery((theme) => theme.breakpoints.up('xl4'))
  return (
    // <Grid item xs={12} sm={12} lg={12} marginTop="0px">
      <PlanVoucherStepWrapper>
        <Grid container item spacing={ xl4 ? 6 : 2} gap={xl4 ? 6 : 2} display={'flex'} p={xl4 ? 9 : 3}>
          {voucherArr?.map((item, index) => {
            if (item?.id === 11) return;
            return (
              // <Grid key={item?.id} container item >
                <FreedomVoucherCard id={item?.id} ischecked={item?.id == selectedVoucher} title={item?.voucherName} description={item?.description} price={item?.VoucherPrice} expirydate={item?.expiryDate} clickOnCard={() => ChangeVoucher(item)} />
              // </Grid>
            );
          })}
        </Grid>
      </PlanVoucherStepWrapper>
    // </Grid>
  );
};

export default VoucherFirstStep;
