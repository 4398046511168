import React, { useState } from 'react';
import { Box, Card, Dialog, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FreedomButton1 from '../FreedomButton1/FreedomButton1';
import FreedomTypographyText from '../FreedomTypographyText/FreedomTypographyText';
import FreedomFollowUpIcon from '../FreedomFollowUpIcon/FreedomFollowUpIcon';
import FreedomButton2 from '../FreedomButton2/FreedomButton2';
import { useNavigate } from 'react-router-dom';
import { WalletUrls } from '../../../url/Links';
import CloseIcon from '../CloseIcon/CloseIcon';
import { images, tableIcon } from '../../../url/SvgLinks';
import { useContext } from 'react';
import { ColorModeContext } from '../../../store';
const dangeralert = tableIcon.dangeralert;
const lightProductSelect = images.lightProductSelect;
const darkProductSelect = images.darkProductSelect;

export const useDialogStyle = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px',
      minWidth: '300px'
      // [theme.breakpoints.down('sm')]:{
      // 	minWidth: '300px'
      // }
    }
  },
  root1: {
    //flexGrow: 1,
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '10px',
      overflowY: 'auto',
      // boxShadow: '0px 16px 40px rgba(230, 182, 74, 0.4)',
      border: `2px solid ${theme.palette.border.popupBorder}`,
      maxWidth: (props) => props.root1MaxWidth || '481px',
      width: '100%',
      [theme.breakpoints.up('xl4')]: {
        maxWidth: (props) => +props.root1MaxWidth?.replace("px", "") * 3 || '1443px',
        border: `6px solid ${theme.palette.border.popupBorder}`,
        borderRadius: '30px',
      }
    },
    '& .css-198bggg-MuiPaper-root-MuiCard-root': {
      overflowY: 'auto'
    }
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogBox: {
    // border: `2px solid ${theme.palette.border.popupBorder}`,
    // boxShadow: '0px 16px 40px rgba(230, 182, 74, 0.4)',
    padding: '20px 0',
    overflowY: 'auto',
    // background: 'linear-gradient(180deg, #FFF0C9 0%, #FFFBF1 100%)',
    background: theme.palette.primary.default,
    borderRadius: '10px',
    maxWidth: (props) => props.dialogBoxMaxWidth || '481px',
    width: '100%',
    [theme.breakpoints.up('xl4')]: {
      padding: '60px 0',
      maxWidth: (props) => +props.dialogBoxMaxWidth?.replace("px", "") * 3 || '1443px',
      borderRadius: '30px',
    }
  }
}));

const InsufficientBalancePopup = ({ dialogBoxMaxWidth, root1MaxWidth, openDialog, setOpenDialog, heading, children, Secondchildren, maxWidth, activeStep, setActiveStep, sendInvite, headingcolor, info, navigateUrl, okButtonText = 'OK' }) => {
  const theme = useTheme();
  const xl4 = useMediaQuery(theme.breakpoints.up('xl4'));
  const dialogStyle = useDialogStyle({ dialogBoxMaxWidth, root1MaxWidth });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const {
    colorMode: { mode }
  } = useContext(ColorModeContext);
  // const handleClose = ()=> {
  //     setOpenDialog(false)
  // }
  const InsufficientBalance = (
    <Box sx={{ overflowY: 'auto' }}>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={dialogStyle.root1}
        BackdropProps={{
          classes: {
            root1: dialogStyle.backDrop
          }
        }}
        sx={{
          backdropFilter: 'blur(5px)',
          maxWidth: maxWidth,
          overflow: 'none',
          [theme.breakpoints.up('xl4')]: {
            maxWidth: +maxWidth?.replace("px", "") * 3
          }
        }}
      >
        <Card className={dialogStyle.dialogBox}>
          <Box
            sx={{
              padding: '16px',
              [theme.breakpoints.down('sm')]: {
                padding: '8px'
              },
              [theme.breakpoints.up('xl4')]: {
                padding: '48px'
              },
            }}
          >
            <CloseIcon
              handleonclick={() => {
                setOpenDialog(false);
              }}
            />
            {/* <Grid container justifyContent='center' spacing={1}> */}
            {/* <Grid item container justifyContent='center'> */}
            {/* <Grid item container spacing={1} alignItems={'center'} justifyContent='center'> */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                gap: '6px', 
                [theme.breakpoints.up('xl4')]: {
                  gap: '18px'
                } }}
            >
              <FreedomFollowUpIcon
                backgroundcolor={'transparent'}
                backgroundhovercolor={'none'}
                bordercolor={'none'}
                // padding='6px'
                height="32px"
                width="32px"
                iconimg={info ? (mode == 'light' ? lightProductSelect : darkProductSelect) : dangeralert}
              />

              {/* <Grid item> */}
              <FreedomTypographyText
                // whiteSpace={'wrap'}
                // textalign={'center'}
                fontsize={isSmallScreen ? '16px' : '24px'}
                lineheight={isSmallScreen ? '24px' : '36px'}
                fontweight={700}
                color={headingcolor || theme.palette.text.primary}
              >
                {heading ? heading : 'Insufficient Balance'}{' '}
              </FreedomTypographyText>
            </Box>
            {/* </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}

            <FreedomTypographyText
              style={{
                letterSpacing: '1.3px',
                margin: xl4 ? "30px 132px 48px 126px" : isSmallScreen ? '18px 0px 24px 0px' : '10px 43px 16px 42px'
              }}
              // whiteSpace={'wrap'}
              // textalign={'center'}
              textalign={'left'}
              fontsize={'12px'}
              lineheight={'18px'}
              fontweight={400}
              textcolor={theme.palette.text.primary}
            >
              {Secondchildren}
            </FreedomTypographyText>
            <FreedomTypographyText
              style={{ margin: xl4 ? "30px 132px 48px 126px" : isSmallScreen ? '18px 0px 24px 0px' : '10px 43px 16px 42px' }}
              // whiteSpace={'wrap'}
              // textalign={'center'}
              fontsize={'12px'}
              lineheight={'18px'}
              fontweight={400}
              textcolor={theme.palette.text.primary}
            >
              {children ? children : 'Your balance is insufficient, please recharge your internal balance through wallet'}
            </FreedomTypographyText>

            {/* </Grid> */}
            {/* <Grid item container xs={12} md={12} lg={12} marginTop={'20.5px'} justifyContent='center'> */}
            {/* <Grid item xs={12}> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {/* <FreedomButton2
                                // disabled={activeStep === 0}
                                onClick={() => {
                                    //   navigate(SettingUrls.borkers)
                                    setOpenDialog(false)
                                }}
                                sx={{ mr: 1 }}
                                variant="outlined"
                                btncolor={theme.palette.icon.iconColor}
                                width="150px"
                                borderradius='10px'
                                height={'48px'}
                            >
                                Cancel
                            </FreedomButton2> */}
              {navigateUrl ? (
                <FreedomButton1
                  height={'48px'}
                  btnbackground={theme.palette.button.buttonbgcolor}
                  // btncolor={theme.palette.background.default}
                  borderradius="10px"
                  width="150px"
                  onClick={() => {
                    navigate(navigateUrl);
                    setOpenDialog(false);
                  }}
                >
                  {okButtonText}
                </FreedomButton1>
              ) : activeStep >= 0 ? (
                <FreedomButton1
                  height={'48px'}
                  btnbackground={theme.palette.button.buttonbgcolor}
                  // btncolor={theme.palette.background.default}
                  borderradius="10px"
                  width="150px"
                  onClick={() => {
                    if (activeStep == 0) {
                      setActiveStep((preActiveStep) => preActiveStep + 1);
                    } else {
                      sendInvite();
                    }
                    setOpenDialog(false);
                  }}
                >
                  {okButtonText}
                </FreedomButton1>
              ) : (
                <FreedomButton1
                  height={'48px'}
                  btnbackground={theme.palette.button.buttonbgcolor}
                  // btncolor={theme.palette.background.default}
                  borderradius="10px"
                  width="150px"
                  onClick={() => {
                    navigate(WalletUrls.cashDeposit);
                    setOpenDialog(false);
                  }}
                >
                  Go to Wallet
                </FreedomButton1>
              )}
            </Box>
            {/* </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
            {/* <Grid container mt={'0.01px'} spacing={2} justifyContent='center' sx={{ p: 1 }}> */}

            {/* </Grid> */}
          </Box>
        </Card>
      </Dialog>
    </Box>
  );

  return <div>{InsufficientBalance}</div>;
};

export default InsufficientBalancePopup;
