import React from 'react';
import { borderRadius, height, styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import { Tooltip } from '@mui/material';

import { appInsights } from '../../../auth/appInsight';
import { getDynamicBorder, getStringProduct } from '../../../utils/utilityFunctions';

const IconBackGround = styled('div')(
  ({ theme, width, height, backgroundcolor, padding, borderradius, backgroundhovercolor, cursor, bordercolor, disabled, responsiveheight, responsivewidth, xllheight, xllwidth, responsiveborderrad, justifycontent }) => ({
    background: backgroundcolor || theme.palette.background.default,
    borderRadius: borderradius || '10px',
    alignItems: 'center',
    padding: padding || '4px',
    width: width || '28px',
    display: 'flex',
    justifyContent: justifycontent || 'center',
    alignItems: 'center',
    height: height || '28px',
    cursor: disabled ? '' : 'pointer',
    border: bordercolor ? `2px solid ${bordercolor} !important` : `2px solid ${theme.palette.border.border} !important`,
    [theme.breakpoints.down('sm')]: {
      height: responsiveheight || '24px',
      width: responsivewidth || '24px',
      borderRadius: responsiveborderrad || '6px'
    },
    [theme.breakpoints.up('xlll')]: {
      height: xllheight || '40px',
      width: xllwidth || '40px',
      // border: `3px solid ${theme.palette.primary.main} !important`,
      borderRadius: '12px'
    },
    [theme.breakpoints.up('xl4')]: {
      borderRadius: getStringProduct(3, borderradius) || getStringProduct(3, '10px'),
      padding: getStringProduct(3, padding) || getStringProduct(3, '4px'),
      height: getStringProduct(3, height) || getStringProduct(3, '28px'),
      width: getStringProduct(3, width) || getStringProduct(3, '28px'),
      border: getDynamicBorder(3, bordercolor) ? `${getDynamicBorder(3, `2px solid ${bordercolor}`)} !important` : `${getDynamicBorder(3, `2px solid ${theme.palette.border.border}`)} !important`,
    }
  })
);
const IconImg = styled('img')(({ theme, imgColor, imageheight, imagewidth, iconbottommargin, margintop, marginleft }) => ({
  height: imageheight,
  width: imagewidth,
  marginBottom: iconbottommargin ? `${iconbottommargin}` : '',
  marginTop: margintop ? `${margintop}` : '',
  marginLeft: marginleft ? `${marginleft}` : '',
  fill: theme.palette.primary.main,
  [theme.breakpoints.up('xlll')]: {
    width: 30,
    height: 30
  },
  [theme.breakpoints.up('xl4')]: {
    width: getStringProduct(3, imagewidth)|| 80,
    height: getStringProduct(3, imageheight) ||  80,
    marginBottom: iconbottommargin ? `${getStringProduct(3, iconbottommargin)}` : '',
    marginTop: margintop ? `${getStringProduct(3, margintop)}` : '',
    marginLeft: marginleft ? `${getStringProduct(3, marginleft)}` : '',
  }
}));

const FreedomFollowUpIcon = (props) => {
  const theme = useTheme();

  const { iconimg, imageheight, imagewidth, padding, backgroundhovercolor, iconbottommargin, marginleft, bordercolor, borderradius, margintop, tooltiptitle, justifycontent, backgroundcolor } = props;
  try {
    return (
      <Tooltip arrow enterTouchDelay={0} title={tooltiptitle ? tooltiptitle : ''} placement="top-start">
        <IconBackGround
          borderradius={borderradius}
          bordercolor={bordercolor}
          backgroundcolor={backgroundcolor}
          backgroundhovercolor={backgroundhovercolor || theme.palette.hoverColor.cardHover}
          justifycontent={justifycontent}
          padding={padding}
          theme={theme}
          {...props}
        >
          <IconImg iconbottommargin={iconbottommargin} margintop={margintop} marginleft={marginleft} src={iconimg} imageheight={imageheight} imagewidth={imagewidth} />
        </IconBackGround>
      </Tooltip>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomFollowUpIcon;
